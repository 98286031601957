import { CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { RowndProvider } from "@rownd/react";
import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import CliniSpanApolloProvider from "./ApolloClient/CliniSpanApolloClient";
import CampaignDashbaord from "./pages/CampaignDashbaord";
import ForgotPassword from "./pages/ForgotPassword";
import Landing from "./pages/Landing";
import Studies from "./pages/Studies";
import AddUsers from "./pages/admin/AddUsers";
import AdminDashboard from "./pages/admin/AdminDashboard";
import AdminLogin from "./pages/admin/AdminLogin";
import AdminStudies from "./pages/admin/AdminStudies";
import Conditions from "./pages/admin/Conditions";
import CreateCampaigns from "./pages/admin/CreateCampaigns";
import DatabaseSearch from "./pages/admin/DatabaseSearch";
import ManageCampaigns from "./pages/admin/ManageCampaigns";
import RecruitmentPartners from "./pages/admin/RecruitmentPartners";
import AdminStudyDetails from "./pages/admin/StudyDetails";
import ViewCampaign from "./pages/admin/ViewCampaign";
import VolunteerData from "./pages/admin/VolunteerData";
import CampaignDetails from "./pages/dhi/CampaignDetails";
import FAQPage from "./pages/dhi/FAQPage";
import ManageProfile from "./pages/dhi/ManageProfile";
import PerformanceDashboard from "./pages/dhi/PerformanceDashboard";
import Resources from "./pages/dhi/Resources";
import Schedule from "./pages/dhi/Schedule";
// import { client } from "./ApolloClient/client";
import Skeleton from "@mui/material/Skeleton";
import { RequireSignIn } from "@rownd/react";
import "bootstrap/dist/css/bootstrap.css";
import AdminContainer from "./components/AdminContainer";
import DHIContainer from "./components/DHIContainer";
import ErrorBoundary from "./components/ErrorBoundary";
import {
  SidebarSkeleton,
  SkeletonHeader,
  TopbarSkeleton,
} from "./components/Menus";
import {
  Roles,
  dhiGroupId,
  partnerGroupId,
  patientGroupId,
} from "./constants/user";
import CommunitiesFeed from "./pages/CommunitiesFeed";
import CommunityFeed from "./pages/CommunityFeed";
import PostDetails from "./pages/CommunityPostDetails";
import Error404 from "./pages/Error404";
import BulkEmailPage from "./pages/admin/BulkEmail";
import AdminInfluencerPortfolio from "./pages/admin/InfluencerPortfolio";
import ManageBilling from "./pages/admin/ManageBilling";
import ActiveCampaigns from "./pages/dhi/ActiveCampaigns";
import CampaignMarket from "./pages/dhi/CampaignMarket";
import DHICampaigns from "./pages/dhi/Campaigns";
import Subscription from "./pages/dhi/Subscription";
import Billing from "./pages/partner/Billing";
import ConfigureStudy from "./pages/partner/ConfigureStudy";
import InfluencerPortfolio from "./pages/partner/InfluencerPortfolio";
import PartnerLogin from "./pages/partner/Login";
import Message from "./pages/partner/Message";
import Organization from "./pages/partner/Organization";
import Outreach from "./pages/partner/Outreach";
import PartnerStudies from "./pages/partner/PartnerStudies";
import PreScreenForm from "./pages/partner/PreScreenForm";
import PartnerStudyDetails from "./pages/partner/StudyDetails";
import PartnerStudyUpload from "./pages/partner/StudyUpload";
import BrowseStudies from "./pages/patient/BrowseStudies";
import PatientDashboard from "./pages/patient/Dashboard";
import FAQ from "./pages/patient/FAQ";
import ManagePatientProfile from "./pages/patient/ManageProfile";
import PatientLogin from "./pages/patient/PatientLogin";
import PatientSignup from "./pages/patient/PatientSignup";
import StudiesForYou from "./pages/patient/StudiesForYou";
import StudyDetails from "./pages/patient/StudyDetails";
import { UserProvider, useUserContext } from "./providers/UserContext";
export default function App() {
  // const ProtectedRoute = ({ user, redirectPath = "/landing", children }) => {
  //   if (!user) {
  //     return <Navigate to={redirectPath} replace />;
  //   }

  //   return children;
  // };

  const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    const { user } = useUserContext();
    const userRoles = user?.roles;

    return (
      <Route
        {...rest}
        render={(props) =>
          roles.some((role) => userRoles.includes(role)) ? (
            <Component {...props} />
          ) : (
            <Navigate to="/" />
          )
        }
      />
    );
  };

  const ProtectedRoute = ({ roles }) => {
    const { user, userLoading } = useUserContext(); // Use the context to get the current user
    const userRoles = user?.roles || [];

    if (userLoading) {
      // Show a loading indicator while the user is loading
      if (roles.includes(Roles.Admin)) {
        return (
          <div className="app">
            <SidebarSkeleton />
            <main className="content">
              <TopbarSkeleton />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Skeleton
                  variant="text"
                  width={700}
                  height={300}
                  style={{ alignSelf: "center" }}
                />
                <Skeleton
                  variant="rect"
                  width={700}
                  height={200}
                  style={{ alignSelf: "center" }}
                />
              </div>
            </main>
          </div>
        );
      }
      return (
        <div className="App">
          <SkeletonHeader />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Skeleton
              variant="text"
              width={700}
              height={300}
              style={{ alignSelf: "center" }}
            />
            <Skeleton
              variant="rect"
              width={700}
              height={300}
              style={{ alignSelf: "center" }}
            />
            <Skeleton
              variant="rect"
              width={700}
              height={300}
              style={{ alignSelf: "center" }}
            />
          </div>
        </div>
      );
    } else {
      if (!user) {
        // If the user is not logged in, redirect to the login page
        return <Navigate to="/" />;
    } else if (!roles.some((role) => userRoles.includes(role))) {
        // If the user is logged in but doesn't have the required role, show an error message
        return <Navigate to="/" />;
      }
    }

    // Render the children (nested routes) if the user has the required role
    return <Outlet />;
  };
  //Usage <PrivateRoute path="/portal-a" component={PortalA} roles={['RoleA']} />

  const initializeFacebook = () => {
    window.FB.init({
      appId: "820787098990382",
      cookie: true,
      xfbml: true,
      version: "v16.0",
    });
  };

  // Load FB SDK
  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://connect.facebook.net/en_US/sdk.js";
    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";
    script.onload = initializeFacebook;
    document.body.appendChild(script);
  }, []);

  // const { user } = useUserContext();
  // const userRoles = user?.roles;
  console.log('process.env', process.env)


  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <RowndProvider
        appKey={
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_ROWND_APP_KEY
            : process.env.REACT_APP_ROWND_APP_KEY_DEV
        }
      >
        <ErrorBoundary>
          <CliniSpanApolloProvider>
            <UserProvider>
              <BrowserRouter>
                <CssBaseline />
                <Routes>
                  {/* <Route index element={<SocialLogin />} /> */}
                  <Route path="/" element={<Landing />} />
                  {/* Start Admin Routes */}
                  <Route element={<ProtectedRoute roles={[Roles.Admin]} />}>
                    {/* <Route path="admin" element={<AdminLogin />}></Route> */}
                    <Route path="forgotpassword" element={<ForgotPassword />} />
                    <Route
                      path="admin/dashboard"
                      element={
                        <RequireSignIn>
                          <AdminDashboard />
                        </RequireSignIn>
                      }
                    />
                    <Route
                      path="admin/volunteer-data"
                      element={
                        <RequireSignIn>
                          <VolunteerData />
                        </RequireSignIn>
                      }
                    />
                    <Route
                      path="admin/database-search"
                      element={
                        <RequireSignIn>
                          <DatabaseSearch />
                        </RequireSignIn>
                      }
                    />
                    <Route
                      path="admin/recruitment-partners"
                      element={
                        <RequireSignIn>
                          <RecruitmentPartners />
                        </RequireSignIn>
                      }
                    />
                    <Route
                      path="admin/billing"
                      element={
                        <RequireSignIn>
                          <ManageBilling />
                        </RequireSignIn>
                      }
                    />
                    <Route
                      path="admin/current-studies"
                      element={
                        <RequireSignIn>
                          <AdminStudies />
                        </RequireSignIn>
                      }
                    />
                    <Route
                      path="admin/current-studies/details/:id"
                      element={
                        <RequireSignIn>
                          <AdminStudyDetails />
                        </RequireSignIn>
                      }
                    />
                    <Route
                      path="admin/add-users"
                      element={
                        <RequireSignIn>
                          <AddUsers />
                        </RequireSignIn>
                      }
                    />
                    <Route
                      path="admin/conditions"
                      element={
                        <RequireSignIn>
                          <Conditions />
                        </RequireSignIn>
                      }
                    />
                    <Route
                      path="admin/studies"
                      element={
                        <RequireSignIn>
                          <Studies />
                        </RequireSignIn>
                      }
                    />
                    <Route
                      path="admin/managecampaigns"
                      element={
                        <RequireSignIn>
                          <ManageCampaigns />
                        </RequireSignIn>
                      }
                    />
                    <Route
                      path="admin/createcampaigns"
                      element={
                        <RequireSignIn>
                          <CreateCampaigns />
                        </RequireSignIn>
                      }
                    />
                    <Route
                      path="admin/influencer-portfolio"
                      element={
                        <RequireSignIn>
                          <AdminInfluencerPortfolio />
                        </RequireSignIn>
                      }
                    />
                    <Route
                      path="admin/mail"
                      element={
                        <RequireSignIn>
                          <AdminContainer>
                            <BulkEmailPage />
                          </AdminContainer>
                        </RequireSignIn>
                      }
                    />
                    <Route
                      path="admin/managecampaigns/view/:campaignId"
                      element={
                        <RequireSignIn>
                          <ViewCampaign />
                        </RequireSignIn>
                      }
                    />
                  </Route>
                  {/* End Admin Routes */}
                  {/* Patient Routes */}
                  {/* <Route path="patient" element={<PatientLogin />} /> */}
                  {/* <Route path="patient/signup" element={<PatientSignup />} /> */}
                  <Route
                    path="patient/dashboard"
                    element={
                      <RequireSignIn
                        signInProps={{ group_to_join: patientGroupId }}
                      >
                        <PatientDashboard />
                      </RequireSignIn>
                    }
                  />
                  <Route
                    path="patient/manage-profile"
                    element={
                      <RequireSignIn
                        signInProps={{ group_to_join: patientGroupId }}
                      >
                        <ManagePatientProfile />
                      </RequireSignIn>
                    }
                  />
                  <Route
                    path="patient/studies-for-you"
                    element={<StudiesForYou />}
                  />
                  <Route
                    path="patient/studies-for-you/study/:id"
                    element={<StudyDetails />}
                  />
                  <Route
                    path="patient/browse-studies"
                    element={<BrowseStudies />}
                  />
                  <Route
                    path="patient/browse-studies/study/:id"
                    element={<StudyDetails />}
                  />
                  <Route path="patient/faqs" element={<FAQ />} />

                  {/* End Patient Routes */}
                  {/* Start Partner Routes */}
                  <Route element={<ProtectedRoute roles={[Roles.Partner]} />}>
                    <Route
                      path="partner/studies"
                      element={
                        <RequireSignIn
                          signInProps={{ group_to_join: partnerGroupId }}
                        >
                          <PartnerStudies />
                        </RequireSignIn>
                      }
                    />
                    <Route
                      path="partner-outreach"
                      element={
                        <RequireSignIn
                          signInProps={{ group_to_join: partnerGroupId }}
                        >
                          <Outreach />
                        </RequireSignIn>
                      }
                    />
                    <Route
                      path="partner/study-upload"
                      // roles={["Partner"]}
                      element={
                        <RequireSignIn
                          signInProps={{ group_to_join: partnerGroupId }}
                        >
                          <PartnerStudyUpload />
                        </RequireSignIn>
                      }
                    />
                    <Route
                      path="partner/outreach/study"
                      element={
                        <RequireSignIn
                          signInProps={{ group_to_join: partnerGroupId }}
                        >
                          <Message />
                        </RequireSignIn>
                      }
                    />
                    <Route
                      path="partner/studies/details/:id"
                      element={
                        <RequireSignIn
                          signInProps={{ group_to_join: partnerGroupId }}
                        >
                          <PartnerStudyDetails />
                        </RequireSignIn>
                      }
                    />
                    <Route
                      path="partner/studies/formbuilder/:id"
                      element={
                        <RequireSignIn
                          signInProps={{ group_to_join: partnerGroupId }}
                        >
                          <PreScreenForm />
                        </RequireSignIn>
                      }
                    />
                    <Route
                      path="partner/influencer-portfolio/"
                      element={
                        <RequireSignIn
                          signInProps={{ group_to_join: partnerGroupId }}
                        >
                          <InfluencerPortfolio />
                        </RequireSignIn>
                      }
                    />
                    <Route
                      path="partner/organization/"
                      element={
                        <RequireSignIn
                          signInProps={{ group_to_join: partnerGroupId }}
                        >
                          <Organization />
                        </RequireSignIn>
                      }
                    />
                    <Route
                      path="partner/campaign-configuration/:campaignId"
                      element={
                        <RequireSignIn
                          signInProps={{ group_to_join: partnerGroupId }}
                        >
                          <ConfigureStudy />
                        </RequireSignIn>
                      }
                    />
                    <Route
                      path="partner/billing/"
                      element={
                        <RequireSignIn
                          signInProps={{ group_to_join: partnerGroupId }}
                        >
                          <Billing />
                        </RequireSignIn>
                      }
                    />
                    {/* <Route
                      path="partner"
                      element={
                        <RequireSignIn
                          signInProps={{ group_to_join: partnerGroupId }}
                        >
                          <PartnerLogin />
                        </RequireSignIn>
                      }
                    /> */}
                    {/* You can add more protected routes as nested routes here */}
                  </Route>
                  {/* End Partner Routes */}
                  {/* Start DHI Routes */}
                  {/* <Route path="dhi/" element={<Login />}></Route>
                <Route path="dhi/signup" element={<Signup />} /> */}

                  <Route
                    path="dhi/campaigndashboard"
                    element={
                      <DHIContainer>
                        <CampaignDashbaord />
                      </DHIContainer>
                    }
                  />
                  <Route
                    path="dhi/dashboard"
                    element={
                      <RequireSignIn
                        signInProps={{ group_to_join: dhiGroupId }}
                      >
                        <DHIContainer>
                          <CampaignMarket />
                        </DHIContainer>
                      </RequireSignIn>
                    }
                  />
                  <Route
                    path="dhi/performance-dashboard"
                    element={
                      <DHIContainer>
                        <PerformanceDashboard />
                      </DHIContainer>
                    }
                  />
                  <Route
                    path="dhi/performance-dashboard/campaign-details/:campaignId"
                    element={
                      <DHIContainer>
                        <CampaignDetails />
                      </DHIContainer>
                    }
                  />
                  <Route
                    path="dhi/profile"
                    element={
                      <RequireSignIn
                        signInProps={{ group_to_join: dhiGroupId }}
                      >
                        <DHIContainer>
                          <ManageProfile />
                        </DHIContainer>
                      </RequireSignIn>
                    }
                  />
                  <Route
                    path="dhi/mycampaigns"
                    element={
                      <RequireSignIn
                        signInProps={{ group_to_join: dhiGroupId }}
                      >
                        <DHIContainer>
                          <DHICampaigns />
                        </DHIContainer>
                      </RequireSignIn>
                    }
                  />
                  <Route
                    path="dhi/mycampaigns/:id"
                    element={
                      <DHIContainer>
                        <ActiveCampaigns />
                      </DHIContainer>
                    }
                  />
                  <Route
                    path="dhi/resources"
                    element={
                      <RequireSignIn
                        signInProps={{ group_to_join: dhiGroupId }}
                      >
                        <DHIContainer>
                          <Resources />
                        </DHIContainer>
                      </RequireSignIn>
                    }
                  />
                  <Route
                    path="dhi/resources/faq"
                    element={
                      <RequireSignIn
                        signInProps={{ group_to_join: dhiGroupId }}
                      >
                        <DHIContainer>
                          <FAQPage />
                        </DHIContainer>
                      </RequireSignIn>
                    }
                  />
                  <Route
                    path="dhi/schedule"
                    element={
                      <RequireSignIn
                        signInProps={{ group_to_join: dhiGroupId }}
                      >
                        <DHIContainer>
                          <Schedule />
                        </DHIContainer>
                      </RequireSignIn>
                    }
                  />
                  {/* <Route
                    path="dhi/billing"
                    element={
                      <RequireSignIn
                        signInProps={{ group_to_join: dhiGroupId }}
                      >
                        <DHIContainer>
                          <Subscription />
                        </DHIContainer>
                      </RequireSignIn>
                    }
                  /> */}

                  {/* End DHI Routes */}
                  <Route
                    path="/:portalPrefix/feed"
                    element={<CommunitiesFeed />}
                  />
                  <Route
                    path="/:portalPrefix/feed/:id"
                    element={<CommunityFeed />}
                  />
                  <Route
                    path="/:portalPrefix/feed/post/:id"
                    element={<PostDetails />}
                  />
                  <Route path="*" element={<Error404 />} />
                </Routes>
              </BrowserRouter>
            </UserProvider>
          </CliniSpanApolloProvider>
        </ErrorBoundary>
      </RowndProvider>
    </LocalizationProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
