import { gql, useMutation, useQuery } from "@apollo/client";
import { Box, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { PartnerHeader } from "../../components/Menus";
import { useUserContext } from "../../providers/UserContext";

//need to update this query
const ORGANIZATION_QUERY = gql`
  query GetOrganization($filter: String, $sortBy: String) {
    organizationMembers(filter: $filter, sortBy: $sortBy) {
      id: _id
      firstName
      lastName
      email
      role
      organizationName
    }
  }
`;

const INVITE_ORGANIZATION_MEMBER = gql`
  mutation InviteOrganizationMember(
    $firstName: String!
    $lastName: String!
    $email: String!
    $role: String!
  ) {
    inviteOrganizationMember(
      userInput: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        role: $role
      }
    ) {
      _id
      firstName
      lastName
      email
      role
    }
  }
`;

function Organization() {
  const { user } = useUserContext();
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("Member");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organizationUsers, setOrganizationUsers] = useState([]);

  const { loading, error, data } = useQuery(ORGANIZATION_QUERY);

  useEffect(() => {
    if (data && data.organizationMembers) {
      setOrganizationUsers(data.organizationMembers);
    }
  }, [data]);

  const [inviteOrganizationMember] = useMutation(INVITE_ORGANIZATION_MEMBER, {
    variables: {
      firstName: firstName,
      lastName: lastName,
      email: email,
      role: role,
    },
    refetchQueries: [{ query: ORGANIZATION_QUERY }],
  });

  console.log("organizationUsers", role);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  const TempTable = ({ data }) => {
    const columns = [
      { field: "firstName", headerName: "First Name", width: 150 },
      { field: "lastName", headerName: "Last Name", width: 150 },
      {
        field: "organizationName",
        headerName: "Organization Name",
        width: 150,
      },
      { field: "role", headerName: "Role", width: 150 },
      { field: "email", headerName: "Email", width: 150 },
    ];

    return (
      <Box style={{ height: 400, width: "95%", margin: 30 }}>
        <DataGrid
          rows={data}
          columns={columns}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#F8F9FC",
            },
          }}
          // slots={{ toolbar: CustomToolbar }}
        />
      </Box>
    );
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const addMember = () => {
    // toast.success("Member Added Successfully");
    const result = inviteOrganizationMember();
    console.log("result", result);
    handleClose();
  };

  // useEffect(() => {
  //   if (data) {
  //     setOrganization(data.organization);
  //   }
  // }, [data]);

  return (
    <div className="App">
      <ToastContainer />
      <PartnerHeader />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Addition</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextField
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <InputLabel id="demo-simple-select-label">Role</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={role}
            label="Role"
            defaultValue="Member"
            onChange={(e) => setRole(e.target.value)}
          >
            <MenuItem value={"Member"}>Member</MenuItem>
            {user?.organizartionId?.ownerUserId &&  user?.organizartionId?.ownerUserId === user?._id && (<MenuItem value={"Owner"}>Owner</MenuItem>)}
          </Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={addMember}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <div>
        {organization.map((org, id) => (
          <div key={id}>
            <div>{org.name}</div>
            <div>{org.location.city}</div>
            <div>{org.location.state}</div>
            <div>{org.location.country}</div>
            <div>{org.platforms}</div>
            <div>{org.therapyAreas}</div>
            <div>{org.followers}</div>
          </div>
        ))}
      </div> */}
      <Box display={"flex"} justifyContent={"flex-end"} mt={5} ml={5} mr={5}>
        <Button
          style={{
            backgroundColor: "#009EC5",
            borderColor: "#009EC5",
            color: "#fff",
          }}
          onClick={handleShow}
        >
          Add User
        </Button>
      </Box>
      {/* <div>
            {organizationUsers.map((user) => (
            <div>
                <div>{user.firstName}</div>
                <div>{user.lastName}</div>
                <div>{user.location.city}</div>
                <div>{user.location.state}</div>
                <div>{user.location.country}</div>
                <div>{user.platforms}</div>
                <div>{user.therapyAreas}</div>
                <div>{user.followers}</div>
                </div>
            ))}
            </div> */}
      <TempTable data={organizationUsers} />
    </div>
  );
}

export default Organization;
