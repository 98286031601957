import { gql, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Pagination from "react-bootstrap/Pagination";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { formatNumberNoDecimal } from "../utilities/format";
import FlippableCard from "./FlippableCard";

const Container = styled.div`
  background-color: ${(props) => props.background || "grey"};
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  padding: 0px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const INFLUENCER_QUERY = gql`
  query GetInfluencers($filter: String, $sortBy: String) {
    influencers(filter: $filter, sortBy: $sortBy) {
      _id
      firstName
      lastName
    location: city
      platforms
      image: avatar
      followers
    }
  }
`;

const CONDITIONS_QUERY = gql`
  query GetConditions {
    conditions {
      name
    }
  }
`;

function InfluencerPortfolio({
  recomendations,
  selectInfluencer,
  deselectInfluencer,
  selections,
}) {
  const navigate = useNavigate();

  const [influencers, setInfluencers] = useState([]);
  const [filterOption, setFilterOption] = useState("1");
  const [filterValue, setFilterValue] = useState("");
  const [sortBy, setSortBy] = useState("followers");
  const [conditions, setConditions] = useState([]);
  const { loading, error, data } = useQuery(CONDITIONS_QUERY);
  const [platforms, setPlatforms] = useState([
    "Facebook",
    "Instagram",
    "Twitter",
    "Tiktok",
  ]);
  const [show, setShow] = useState(false);

  console.log("influencers from query", influencers);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  
  const [filteredData, setFilteredData] = useState(influencers);
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const {
    loading: influencersLoading,
    error: influencersError,
    data: influencersData,
  } = useQuery(INFLUENCER_QUERY, {
    variables: {
      filter: filterOption,
      sortBy: "followers",
    },
  });

  useEffect(() => {
    if (influencersData) {
      setInfluencers(influencersData.influencers);
      setFilteredData(influencersData.influencers);
    }
  }, [influencersData]);


  useEffect(() => {
    applyFilter();
  }, [filterOption, filterValue]);

  const applyFilter = () => {
    let filtered = influencers;

    if (filterOption === "1" && filterValue) {
      filtered = influencers.filter(item => item.location && item.location.includes(filterValue));
    } else if (filterOption === "2" && filterValue) {
      filtered = influencers.filter(item => item.therapyArea && item.therapyArea.includes(filterValue));
    } else if (filterOption === "3" && filterValue) {
      filtered = influencers.filter(item => item.platforms && item.platforms.includes(filterValue));
    }


    setFilteredData(filtered);
  };


  const displaySocialIcons = (platformName) => {
    switch (platformName) {
      case "Facebook":
        return (
          <FontAwesomeIcon
            icon={["fab", "facebook"]}
            style={{
              alignSelf: "center",
              fontSize: "25px",
              color: "#009EC5",
              border: "1px solid #009EC5",
              borderRadius: "5px",
              padding: "2px",
              margin: "2px",
            }}
          />
        );
      case "Instagram":
        return (
          <FontAwesomeIcon
            icon={["fab", "instagram"]}
            style={{
              alignSelf: "center",
              fontSize: "25px",
              color: "#d6249f",
              border: "1px solid #009EC5",
              borderRadius: "5px",
              padding: "2px",
              margin: "2px",
            }}
          />
        );
      case "Twitter":
        return (
          <FontAwesomeIcon
            icon={["fab", "twitter"]}
            style={{
              alignSelf: "center",
              fontSize: "25px",
              color: "#1DA1F2",
              border: "1px solid #009EC5",
              borderRadius: "5px",
              padding: "2px",
              margin: "2px",
            }}
          />
        );
      case "Tiktok":
        return (
          <FontAwesomeIcon
            icon={["fab", "tiktok"]}
            style={{
              alignSelf: "center",
              fontSize: "25px",
              color: "#000",
              border: "1px solid #009EC5",
              borderRadius: "5px",
              padding: "2px",
              margin: "2px",
            }}
          />
        );
      default:
        return (
          <FontAwesomeIcon
            icon={["fab", "facebook"]}
            style={{
              alignSelf: "center",
              fontSize: "25px",
              color: "#000",
              border: "1px solid #009EC5",
              borderRadius: "5px",
              padding: "2px",
              margin: "2px",
            }}
          />
        );
    }
  };

  return (
    <div className="App">
      {/* <PartnerHeader /> */}
      {/* <TemplateUpload /> */}
      {/* <MediaLibrary isOpen={show} onClose={() => setShow(false)} /> */}
      {/* <Button onClick={() => setShow(true)}>Open Media Library</Button> */}
      <div style={{ paddingRight: 20, paddingLeft: 20, marginTop: 10 }}>
        <Row className="g-2" xs>
          <Col xs>
            <FloatingLabel controlId="floatingSelectGrid" label="Filter By">
              <Form.Select
                aria-label="Floating label select example"
                onChange={(e) => setFilterOption(e.currentTarget.value)}
              >
                {/* <option>Open this select menu</option> */}
                <option value="1">Location</option>
                <option value="2">Therapy Area</option>
                <option value="3">Platform</option>
              </Form.Select>
            </FloatingLabel>
          </Col>
          <Col md>
            <FloatingLabel controlId="floatingInputGrid" label="">
              {filterOption === "1" && (
                <Form.Control type="location" placeholder="Enter Location"  onChange={(e) => setFilterValue(e.currentTarget.value)} />
              )}
              {filterOption === "2" && (
                <Form.Select
                  aria-label="Floating label select example"
                  onChange={(e) => setFilterValue(e.currentTarget.value)}
                >
                  {/* <option>Open this select menu</option> */}
                  {data &&
                    data.conditions.map((condition, index) => (
                      <option key={index} value={condition.name}>
                        {condition.name}
                      </option>
                    ))}
                </Form.Select>
              )}
              {filterOption === "3" && (
                <Form.Select
                  aria-label="Floating label select example"
                  onChange={(e) => setFilterValue(e.currentTarget.value)}
                >
                  {/* <option>Open this select menu</option> */}
                  {platforms &&
                    platforms.map((platform, index) => (
                      <option key={index} value={platform}>
                        {platform}
                      </option>
                    ))}
                </Form.Select>
              )}
            </FloatingLabel>
          </Col>
          <Col
            xs
            style={{
              width: "10%",
              maxWidth: "10%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="primary"
              onClick={() => null}
              style={{ alignSelf: "center" }}
            >
              Filter
            </Button>
          </Col>
        </Row>
      </div>

      {recomendations && (
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              padding: "10px",
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "left",
              marginTop: "10px",
            }}
          >
            Recommended Influencers
          </div>
          <div className="study-grid">
            {recomendations.map((influencer, index) => {
              const influencerIndex = selections.findIndex(
                (inf) => inf._id === influencer._id
              );
              return (
                <div className="study-card" key={index}>
                  <FlippableCard
                    style={{ width: "100%", height: "100%" }}
                    key={index}
                    influencerSelected={influencerIndex !== -1}
                    onClick={() => {
                      //if influencer not in selections select it, else deselect

                      if (influencerIndex !== -1) {
                        deselectInfluencer(influencer);
                      } else {
                        selectInfluencer(influencer);
                      }
                    }}
                    front={
                      <Container
                        key={index}
                        onClick={() => {
                          //   navigate("study/" + study.nctNumber, {
                          //     state: { influencer: influencer },
                          //   });
                        }}
                        style={{ backgroundColor: "#fff", gap: "10px" }}
                      >
                        {/* <AccountCircleIcon
                    style={{
                      fontSize: "100px",
                      color: "black",
                      cursor: "pointer",
                    }}
                  /> */}
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                          }}
                        >
                          {influencer?.avatar && (
                            <img
                              src={influencer.avatar}
                              style={{
                                height: "50px",
                                width: "50px",
                                marginRight: "10px",
                              }}
                            />
                          )}

                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "left",
                            }}
                          >
                            <h4 style={{ margin: 0 }}>
                              {influencer?.firstName} {influencer?.lastName}
                            </h4>
                            <p style={{ margin: 0 }}>
                              <strong>Location:</strong>{" "}
                              <span style={{ color: "blue" }}>
                                {/* {`${influencer?.location?.city || ""}, ${
                            influencer?.location?.state ||
                            influencer?.location?.country ||
                            ""
                          }`} */}
                                {influencer?.location || ""},
                              </span>
                            </p>
                          </Box>
                        </Box>
                        {/* <p>Platforms: {displaySocialIcons(influencer.platforms.toString())}</p> */}
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "left",
                          }}
                        >
                          <strong> Therapy Areas: </strong>{" "}
                          <p>
                            <span style={{ color: "blue" }}>
                              {influencer?.therapyAreas &&
                                influencer?.therapyAreas
                                  .map((area) => area.name)
                                  .join(", ")}
                            </span>
                          </p>
                        </Box>
                        <Grid
                          container
                          sx={{ marginTop: "16px" }}
                          alignItems="center"
                        >
                          <Grid item xs={6} sx={{ textAlign: "left" }}>
                            <strong> Followers: </strong>
                            <p>
                              {" "}
                              {(influencer?.followers &&
                                formatNumberNoDecimal(influencer.followers)) ||
                                0}
                            </p>
                          </Grid>

                          <Grid
                            item
                            xs={6}
                            sx={{ textAlign: "center", marginLeft: "-30%" }}
                          >
                            <strong> Reach: </strong>{" "}
                            <p>
                              {(influencer?.followers &&
                                formatNumberNoDecimal(
                                  influencer.followers * 2000
                                )) ||
                                0}
                            </p>
                          </Grid>
                        </Grid>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            textAlign: "left",
                          }}
                        >
                          <strong> Social Platforms</strong>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginBottom: "10px",
                            }}
                          >
                            {influencer?.platforms.map((platform, index) => (
                              <>{displaySocialIcons(platform)}</>
                            ))}
                          </div>
                        </Box>
                      </Container>
                    }
                    back={
                      <Container
                        key={index}
                        onClick={() => {
                          //   navigate("study/" + study.nctNumber, {
                          //     state: { influencer: influencer },
                          //   });
                        }}
                        style={{ backgroundColor: "#fff", gap: "10px" }}
                      >
                        {/* <AccountCircleIcon
                    style={{
                      fontSize: "100px",
                      color: "black",
                      cursor: "pointer",
                    }}
                  /> */}
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                          }}
                        >
                          {influencer?.avatar && (
                            <img
                              src={influencer.avatar}
                              style={{
                                height: "50px",
                                width: "50px",
                                marginRight: "10px",
                              }}
                            />
                          )}

                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "left",
                            }}
                          >
                            <h4 style={{ margin: 0 }}>
                              {influencer?.firstName} {influencer?.lastName}
                            </h4>
                            <p style={{ margin: 0 }}>
                              <strong>Location:</strong>{" "}
                              <span style={{ color: "blue" }}>
                                {/* {`${influencer?.location?.city || ""}, ${
                            influencer?.location?.state ||
                            influencer?.location?.country ||
                            ""
                          }`} */}
                                {influencer?.location || ""},
                              </span>
                            </p>
                          </Box>
                        </Box>
                        {/* <p>Platforms: {displaySocialIcons(influencer.platforms.toString())}</p> */}
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "left",
                          }}
                        >
                          <strong> Therapy Areas: </strong>{" "}
                          <p>
                            <span style={{ color: "blue" }}>
                              {influencer?.therapyAreas &&
                                influencer?.therapyAreas
                                  .map((area) => area.name)
                                  .join(", ")}
                            </span>
                          </p>
                        </Box>
                        <Grid
                          container
                          sx={{ marginTop: "16px" }}
                          alignItems="center"
                        >
                          <Grid item xs={6} sx={{ textAlign: "left" }}>
                            <strong> Followers: </strong>
                            <p>
                              {" "}
                              {(influencer?.followers &&
                                formatNumberNoDecimal(influencer.followers)) ||
                                0}
                            </p>
                          </Grid>

                          <Grid
                            item
                            xs={6}
                            sx={{ textAlign: "center", marginLeft: "-30%" }}
                          >
                            <strong> Reach: </strong>{" "}
                            <p>
                              {(influencer?.followers &&
                                formatNumberNoDecimal(
                                  influencer.followers * 2000
                                )) ||
                                0}
                            </p>
                          </Grid>
                        </Grid>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            textAlign: "left",
                          }}
                        >
                          <strong> Social Platforms</strong>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginBottom: "10px",
                            }}
                          >
                            {influencer?.platforms.map((platform, index) => (
                              <>{displaySocialIcons(platform)}</>
                            ))}
                          </div>
                        </Box>
                      </Container>
                    }
                  />
                  {/* <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      borderTop: "1px solid #ddd",
                    }}
                  >
                    <Button
                      key={index}
                      variant={influencerIndex !== -1 ? "danger" : "primary"}
                      onClick={() => {
                        //if influencer not in selections select it, else deselect

                        if (influencerIndex !== -1) {
                          deselectInfluencer(influencer);
                        } else {
                          selectInfluencer(influencer);
                        }
                      }}
                    >
                      {influencerIndex !== -1
                        ? "Deselect Influencer"
                        : "Select Influencer"}
                    </Button>
                  </Box> */}
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div
        style={{
          // backgroundColor: "lightblue",
          textAlign: "left",
          padding: "10px",
          fontSize: "20px",
          fontWeight: "bold",
          marginTop: "10px",
        }}
      >
        All Influencers
      </div>
      <div className="study-grid">
        {currentItems &&
          currentItems.map((influencer, index) => {
            const influencerIndex = selections.findIndex(
              (inf) => inf._id === influencer._id
            );
            return (
              <div className="study-card" key={index}>
                <FlippableCard
                  style={{ width: "100%", height: "100%" }}
                  key={index}
                  influencerSelected={influencerIndex !== -1}
                  onClick={() => {
                    //if influencer not in selections select it, else deselect

                    if (influencerIndex !== -1) {
                      deselectInfluencer(influencer);
                    } else {
                      selectInfluencer(influencer);
                    }
                  }}
                  front={
                    <Container
                      key={index}
                      onClick={() => {
                        //   navigate("study/" + study.nctNumber, {
                        //     state: { influencer: influencer },
                        //   });
                      }}
                      style={{ backgroundColor: "#fff", gap: "10px" }}
                    >
                      {/* <AccountCircleIcon
                    style={{
                      fontSize: "100px",
                      color: "black",
                      cursor: "pointer",
                    }}
                  /> */}
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        {influencer?.image ? (
                          <img
                            src={influencer.image}
                            style={{
                              height: "50px",
                              width: "50px",
                              marginRight: "10px",
                            }}
                          />
                        ) : (
                          <img
                            src={
                              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSW_el0K5Ct5T9M0vXJDEXasgd2QfdAVZdC8g&s"
                            }
                            style={{
                              height: "50px",
                              width: "50px",
                              marginRight: "10px",
                            }}
                          />
                        )}

                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "left",
                          }}
                        >
                          <h4 style={{ margin: 0 }}>
                            {influencer?.firstName} {influencer?.lastName}
                          </h4>
                          <p style={{ margin: 0 }}>
                            <strong>Location:</strong>{" "}
                            <span style={{ color: "blue" }}>
                              {/* {`${influencer?.location?.city || ""}, ${
                            influencer?.location?.state ||
                            influencer?.location?.country ||
                            ""
                          }`} */}
                              {influencer?.location || ""},
                            </span>
                          </p>
                        </Box>
                      </Box>
                      {/* <p>Platforms: {displaySocialIcons(influencer.platforms.toString())}</p> */}
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "left",
                        }}
                      >
                        <strong> Therapy Areas: </strong>{" "}
                        <p>
                          <span style={{ color: "blue" }}>
                            {influencer?.therapyAreas &&
                              influencer?.therapyAreas
                                .map((area) => area.name)
                                .join(", ")}
                          </span>
                        </p>
                      </Box>
                      <Grid
                        container
                        sx={{ marginTop: "16px" }}
                        alignItems="center"
                      >
                        <Grid item xs={6} sx={{ textAlign: "left" }}>
                          <strong> Followers: </strong>
                          <p>
                            {" "}
                            {(influencer?.followers &&
                              formatNumberNoDecimal(influencer.followers)) ||
                              0}
                          </p>
                        </Grid>

                        <Grid
                          item
                          xs={6}
                          sx={{ textAlign: "center", marginLeft: "-30%" }}
                        >
                          <strong> Reach: </strong>{" "}
                          <p>
                            {(influencer?.followers &&
                              formatNumberNoDecimal(
                                influencer.followers * 2000
                              )) ||
                              0}
                          </p>
                        </Grid>
                      </Grid>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          textAlign: "left",
                        }}
                      >
                        <strong> Social Platforms</strong>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: "10px",
                          }}
                        >
                          {influencer?.platforms.map((platform, index) => (
                            <>{displaySocialIcons(platform)}</>
                          ))}
                        </div>
                      </Box>
                    </Container>
                  }
                  back={
                    <Container
                      key={index}
                      onClick={() => {
                        //   navigate("study/" + study.nctNumber, {
                        //     state: { influencer: influencer },
                        //   });
                      }}
                      style={{ backgroundColor: "#fff", gap: "10px" }}
                    >
                      {/* <AccountCircleIcon
                    style={{
                      fontSize: "100px",
                      color: "black",
                      cursor: "pointer",
                    }}
                  /> */}
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        {influencer?.image && (
                          <img
                            src={influencer.image}
                            style={{
                              height: "50px",
                              width: "50px",
                              marginRight: "10px",
                            }}
                          />
                        )}

                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "left",
                          }}
                        >
                          <h4 style={{ margin: 0 }}>
                            {influencer?.firstName} {influencer?.lastName}
                          </h4>
                          <p style={{ margin: 0 }}>
                            <strong>Location:</strong>{" "}
                            <span style={{ color: "blue" }}>
                              {/* {`${influencer?.location?.city || ""}, ${
                            influencer?.location?.state ||
                            influencer?.location?.country ||
                            ""
                          }`} */}
                              {influencer?.location || ""},
                            </span>
                          </p>
                        </Box>
                      </Box>
                      {/* <p>Platforms: {displaySocialIcons(influencer.platforms.toString())}</p> */}
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "left",
                        }}
                      >
                        <strong> Therapy Areas: </strong>{" "}
                        <p>
                          <span style={{ color: "blue" }}>
                            {influencer?.therapyAreas &&
                              influencer?.therapyAreas
                                .map((area) => area.name)
                                .join(", ")}
                          </span>
                        </p>
                      </Box>
                      <Grid
                        container
                        sx={{ marginTop: "16px" }}
                        alignItems="center"
                      >
                        <Grid item xs={6} sx={{ textAlign: "left" }}>
                          <strong> Followers: </strong>
                          <p>
                            {" "}
                            {(influencer?.followers &&
                              formatNumberNoDecimal(influencer.followers)) ||
                              0}
                          </p>
                        </Grid>

                        <Grid
                          item
                          xs={6}
                          sx={{ textAlign: "center", marginLeft: "-30%" }}
                        >
                          <strong> Reach: </strong>{" "}
                          <p>
                            {(influencer?.followers &&
                              formatNumberNoDecimal(
                                influencer.followers * 2000
                              )) ||
                              0}
                          </p>
                        </Grid>
                      </Grid>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          textAlign: "left",
                        }}
                      >
                        <strong> Social Platforms</strong>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: "10px",
                          }}
                        >
                          {influencer?.platforms.map((platform, index) => (
                            <>{displaySocialIcons(platform)}</>
                          ))}
                        </div>
                      </Box>
                    </Container>
                  }
                />
                {/* <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    borderTop: "1px solid #ddd",
                  }}
                >
                  <Button
                    key={index}
                    variant={influencerIndex !== -1 ? "danger" : "primary"}
                    onClick={() => {
                      //if influencer not in selections select it, else deselect

                      if (influencerIndex !== -1) {
                        deselectInfluencer(influencer);
                      } else {
                        selectInfluencer(influencer);
                      }
                    }}
                  >
                    {influencerIndex !== -1
                      ? "Deselect Influencer"
                      : "Select Influencer"}
                  </Button>
                </Box> */}
              </div>
            );
          })}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination>
          <Pagination.Prev
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          />
          {[...Array(Math.ceil(filteredData.length / itemsPerPage))].map(
            (e, i) => (
              <Pagination.Item
                key={i + 1}
                active={i + 1 === currentPage}
                onClick={() => handlePageChange(i + 1)}
              >
                {i + 1}
              </Pagination.Item>
            )
          )}
          <Pagination.Next
            disabled={
              currentPage === Math.ceil(filteredData.length / itemsPerPage)
            }
            onClick={() => handlePageChange(currentPage + 1)}
          />
        </Pagination>
      </div>
    </div>
  );
}

export default InfluencerPortfolio;
