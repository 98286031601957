import { gql, useMutation, useQuery } from "@apollo/client";
import { Card } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal,OverlayTrigger, Tooltip, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import styled from "styled-components";
import MediaTabs from "../../components/MediaTabs";
import { useUserContext } from "../../providers/UserContext";
import { Box, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import MediaLibrary from "../../components/MediaModal";

const SCHEDULE_POST_MUTATION = gql`
  mutation schedulePost(
    $post: String
    $originalDate: String
    $date: String
    $timezoneOffset: Int
    $userTimezone: String
    $platform: Platform
    $campaignId: String
    $file: [FileUpload]
    $commentDisabled: Boolean
    $duetDisabled: Boolean
    $stitchDisabled: Boolean
    $postVisibility: String
  ) {
    schedulePost(
      postInput: {
        post: $post
        originalDate: $originalDate
        date: $date
        timezoneOffset: $timezoneOffset
        userTimezone: $userTimezone
        platform: $platform
        campaignId: $campaignId
        file: $file
        commentDisabled: $commentDisabled
        duetDisabled: $duetDisabled
        stitchDisabled: $stitchDisabled
        postVisibility: $postVisibility
      }
    ) {
      response
    }
  }
`;

const UPLOAD_FILE_MUTATION = gql`
  mutation UploadFile($file: Upload!) {
    uploadFile(file: $file) {
      _id
      name
      url
    }
  }
`;

const GET_FILES_QUERY = gql`
  query GetFiles($portalType: String!) {
    getFiles(portalType: $portalType) {
      _id
      name
      url
      fileType
    }
  }
`;

const CREATOR_INFO = gql`
  query creatorInfo {
    creatorInfo {
      creator_username
      creator_nickname
      creator_avatar_url
      privacy_level_options
      max_video_post_duration_sec
      comment_disabled
      duet_disabled
      stitch_disabled
    }
  }
`;

const Text = styled.p`
  color: #000;
`;

function ActiveCampaigns() {
  const { user, userPrimaryRole, facebookUserAccessToken } = useUserContext();
  const accountType = userPrimaryRole();
  const [mediaModalOpen, setMediaModalOpen] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const item = location.state.item;
  const fileInput = useRef(null);

  //need to discuss asap
  const handleCopyClick = () => {
    const nctNumber = item?.nctNumber;
    //need to modify link for each platform
    const link = `${window.location.origin}/patient/browse-studies/study/${nctNumber}?referral=${user?._id}&campaign=${item?._id}&utm_source=sourceName&utm_medium=mediumName&utm_campaign=campaignName`;
    navigator.clipboard.writeText(link);
    alert("Link copied to clipboard");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    // Here you can handle the file upload
  };

  console.log("item", item);

  const openModal = () => {
    if (user?.dhiOnboardingComplete) {
      setOpen(true);
    } else {
      setOpenWarning(true);
    }
    setOpen(true);
  };

  function WarningModal({ show, handleClose }) {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You must complete the onboarding process before scheduling a post.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }


    const [page, setPage] = useState(1);
  const [post, setPost] = useState("");
  const [date, setDate] = useState("");
  const [platform, setPlatform] = useState("");
  const [file, setFile] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [openMediaModal, setOpenMediaModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [tiktokUserInfo, setTiktokUserInfo] = useState(null);
  const [postVisibility, setPostVisibility] = useState(tiktokUserInfo?.privacy_level_options[0]);
  const [commentDisabled, setCommentDisabled] = useState(false);
  const [duetDisabled, setDuetDisabled] = useState(false);
  const [stitchDisabled, setStitchDisabled] = useState(false);
  const [isDisclosureEnabled, setDisclosureEnabled] = useState(false);
  const [isBrand, setIsBrand] = useState(false);
  const [isBrandedContent, setIsBrandedContent] = useState(false);

  const [schedulePost] = useMutation(SCHEDULE_POST_MUTATION, {
    variables: {
      post: item?.campaignId?.caption,
      date,
      platform,
      campaignId: item?._id,
      file: selectedFiles,
      commentDisabled,
      duetDisabled,
      stitchDisabled,
    },
  });

  const saveSelection = async (selectedFiles) => {
    // save the selected media to the campaignData object
    console.log("selectedFiles in save selection", selectedFiles);
    // setFile({
    //   media: selectedFiles,
    // });
    setSelectedFiles(selectedFiles);
    //close modal once done
    
    if(platform === 'Tiktok'){
      setPage(3) 
      setOpenMediaModal(false);
      setOpen(true);
    } else {
      setOpenMediaModal(false);
      handleSubmit(selectedFiles);
      }
    

  };

  const handleSubmit = async (filesPassed) => {
    // console.log("Post:", post);
    console.log("Date:", date);
    console.log("Platform:", platform);
    // console.log("File:", file);
    // saveSelection(selectedFiles);
    const localDate = new Date(date);
    
    const utcDate = new Date(
      localDate.getTime() - localDate.getTimezoneOffset() * 60000
    );
    const utcDateString = utcDate.toISOString();
    console.log("utcDateString", utcDateString);

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    console.log("userTimezone", userTimezone);

    // if (facebookUserAccessToken && platform === "Instagram") {
    //   shareInstagramPost();
    //   toast.success("Post scheduled successfully");
    //   handleClose();
    //   return;
    // }

    // console.log("item before scheduling post", item);
    // console.log("scheduling post", {
    //   post: platform === 'Tiktok' && post ? post : item?.campaignId?.caption,
    //   originalDate: date,
    //   date: utcDateString,
    //   timezoneOffset: localDate.getTimezoneOffset(),
    //   userTimezone,
    //   platform,
    //   campaignId: item?.campaignId?.id,
    //   file: selectedFiles.length > 0 ? selectedFiles : filesPassed,
    //   commentDisabled,
    //   duetDisabled,
    //   stitchDisabled,
    //   postVisibility,
    // }
      
    // );
    // const res = { data: { schedulePost: { response: "Success" } } };

    const res = await schedulePost({
      variables: {
        post: platform === 'Tiktok' && post ? post : item?.campaignId?.caption,
        originalDate: date,
        date: utcDateString,
        timezoneOffset: localDate.getTimezoneOffset(),
        userTimezone,
        platform,
        campaignId: item?.campaignId?.id,
        file: selectedFiles.length > 0 ? selectedFiles : filesPassed,
        commentDisabled,
        duetDisabled,
        stitchDisabled,
        postVisibility,
      },
    }); //need to check response and display success message or error message
    console.log("res", res);
    if (res.data.schedulePost.response === "Success") {
      toast.success("Post scheduled successfully");
      setSelectedFiles([]);
      setPost("");
      setDate("");
      setPlatform("");
      setFile(null);
      setCommentDisabled(false);
      setDuetDisabled(false);
      setStitchDisabled(false);
      setDisclosureEnabled(false);
      setIsBrand(false);
      setIsBrandedContent(false);
    } else {
      toast.error("Error scheduling post");
    }

    setOpen(false);
    setOpenMediaModal(false);
    setPage(1);
  };
    
    // const { data, loading, error, refetch } = useQuery(GET_FILES_QUERY, {
    //   variables: {
    //     portalType: accountType,
    //   },
    // });
    const { data: creatorInfoData } = useQuery(CREATOR_INFO, {
      skip: !user || platform !== "Tiktok",
    });


    console.log("item", item?.campaignId?.caption);
    console.log("selectedFiles", selectedFiles);
    

    const getFacebookPages = () => {
      return new Promise((resolve) => {
        window.FB.api(
          "me/accounts",
          { access_token: facebookUserAccessToken },
          (response) => {
            resolve(response.data);
          }
        );
      });
    };

    const getInstagramAccountId = (facebookPageId) => {
      return new Promise((resolve) => {
        window.FB.api(
          facebookPageId,
          {
            access_token: facebookUserAccessToken,
            fields: "instagram_business_account",
          },
          (response) => {
            resolve(response.instagram_business_account.id);
          }
        );
      });
    };

    const createMediaObjectContainer = (instagramAccountId) => {
      return new Promise((resolve) => {
        window.FB.api(
          `${instagramAccountId}/media`,
          "POST",
          {
            access_token: facebookUserAccessToken,
            image_url: selectedFiles[0].url,
            caption: item?.campaignId?.caption,
          },
          (response) => {
            resolve(response.id);
          }
        );
      });
    };

    const publishMediaObjectContainer = (
      instagramAccountId,
      mediaObjectContainerId
    ) => {
      return new Promise((resolve) => {
        window.FB.api(
          `${instagramAccountId}/media_publish`,
          "POST",
          {
            access_token: facebookUserAccessToken,
            creation_id: mediaObjectContainerId,
          },
          (response) => {
            resolve(response.id);
          }
        );
      });
    };

    const shareInstagramPost = async () => {
      // setIsSharingPost(true);
      try {
        const facebookPages = await getFacebookPages();
        const instagramAccountId = await getInstagramAccountId(
          facebookPages[0].id
        );
        const mediaObjectContainerId = await createMediaObjectContainer(
          instagramAccountId
        );

        console.log("fb pages", facebookPages);
        console.log("instagram account id", instagramAccountId);
        console.log("media object container id", mediaObjectContainerId);

        await publishMediaObjectContainer(
          instagramAccountId,
          mediaObjectContainerId
        );
      } catch (error) {
        console.error("Error sharing Instagram post:", error);
      }

      // setIsSharingPost(false);

      // // Reset the form state
      // setImageUrl("");
      // setPostCaption("");
    };

    // useEffect(() => {
    //   if (openMediaModal) {
    //     refetch();
    //   }
    // }, [openMediaModal, refetch]);
    // useEffect(() => {
    //   if (openMediaModal && selectedTab === 1) {
    //     refetch();
    //   }
    // }, [openMediaModal, refetch, selectedTab]);

    // useEffect(() => {
    //   let files;
    //   if(data?.getFiles) {
    //     if(platform === "Instagram") {
    //       files = data.getFiles.filter(file => file.fileType === "image" || file.fileType === "video");
    //     } else if(platform === "Twitter") {
    //       files = data.getFiles.filter(file => file.fileType === "image" );
    //     }  else if(platform === "Tiktok") {
    //       files = data.getFiles.filter(file => file.fileType === "video");
    //     } else if (platform === "Facebook") {
    //       files = data.getFiles.filter(file => file.fileType === "image" || file.fileType === "video");
    //     }
    //     else {
    //       files = data.getFiles;
    //     }
    //     setFiles({getFiles: files});
    //   }
    // }, [data, platform]);

    useEffect(() => {
      if (creatorInfoData) {
        setTiktokUserInfo(creatorInfoData.creatorInfo);
      }
    }, [creatorInfoData]);
          

    const handleFileCheck = (file) => {
      if (platform === "Tiktok") {
        setSelectedFiles([file]);
        return;
      }
      setSelectedFiles((prev) => {
        console.log("prev", prev); //prev is an object with the following structure, need to have the file upload response return the file model data instead to fix

        // {lastModified
        //   :
        //   1705441420842
        //   lastModifiedDate
        //   :
        //   Tue Jan 16 2024 15:43:40 GMT-0600 (Central Standard Time) {}
        //   name
        //   :
        //   "39305ef3d750.jpg"
        //   size
        //   :
        //   140035
        //   type
        //   :
        //   "image/jpeg"
        //   webkitRelativePath
        //   :
        //   ""}
        prev = prev || []; // Use an empty array if prev is undefined
        return prev.includes(file)
          ? prev.filter((selectedFile) => selectedFile._id !== file._id)
          : [...prev, file];
      });
    };


    console.log("user", user, accountType);




    console.log(date ? 'scheduled date ' + date + ' Offset: ' + new Date(date).getTimezoneOffset() : 'no date selected');
    console.log(date ? 'scheduled date ' + date + ' Offset: ' + new Date(date).getTimezoneOffset() : 'no date selected');


    const handleNextPage = () => {
      setPage(page + 1);
      if(page + 1 === 2){
        setOpenMediaModal(true);
        setOpen(false);
      }
    };

    const handlePreviousPage = () => {
      setPage(page - 1);
    };

    const handleDisclosureToggle = () => {
      setDisclosureEnabled(!isDisclosureEnabled);
    };
  
    const handleBrandToggle = () => {
      setIsBrand(!isBrand);
    };
  
    const handleBrandedContentToggle = () => {
      setIsBrandedContent(!isBrandedContent);
    };

    let declarationMessage;
    if (isBrand && isBrandedContent) {
      declarationMessage = (
        <>
          By posting, you agree to TikTok&apos;s <a href="https://www.tiktok.com/legal/page/global/bc-policy/en">Branded Content Policy</a> and <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en">Music Usage Confirmation</a>.
        </>
      );
    } else if (isBrandedContent) {
      declarationMessage = (
        <>
          By posting, you agree to TikTok&apos;s <a href="https://www.tiktok.com/legal/page/global/bc-policy/en">Branded Content Policy</a> and <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en">Music Usage Confirmation</a>.
        </>
      );
    } else if (isBrand) {
      declarationMessage = (
        <>
          By posting, you agree to TikTok&apos;s <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en">Music Usage Confirmation</a>.
        </>
      );
    } else {
      declarationMessage = (
        <>
          By posting, you agree to TikTok&apos;s <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en">Music Usage Confirmation</a>.
        </>
      );
    }

    const isPublishDisabled = isDisclosureEnabled && !isBrand && !isBrandedContent;

    let publishDeclaration;

    if (isBrand && isBrandedContent) {
      publishDeclaration = "Your photo/video will be labeled as 'Paid partnership.'";
    } else if (isBrandedContent) {
      publishDeclaration = "Your photo/video will be labeled as 'Paid partnership.'";
    } else if (isBrand) {
      publishDeclaration = "Your photo/video will be labeled as 'Promotional content.'";
    } else {
      publishDeclaration = "";
    }

    const TiktokVerified = user?.socialAuth.find(
      (auth) => auth.platform === "Tiktok" && auth.verified
    );
    const InstagramVerified = user?.socialAuth.find(
      (auth) => auth.platform === "Instagram" && auth.verified
    );
    const TwitterVerified = user?.socialAuth.find(
      (auth) => auth.platform === "Twitter" && auth.verified
    );
  


    

  const downloadCampaignMaterials = () => {
    for (let i = 0; i < item.materials.length; i++) {
      const url = item.materials[i].url;
      window.open(url);
    }
  };

  return (
    <div className="App">
      <ToastContainer />
      <MediaLibrary
        isOpen={openMediaModal}
        onClose={() => setOpenMediaModal(false)}
        saveSelection={saveSelection}
        accountType={accountType}
        singleSelection
        primaryActionText={platform === "Tiktok" ? "Next" : "Schedule Post"}
        user={user}
        platform={platform}
      />
      <Modal show={open} onHide={() => setOpen(false)} style={{ maxHeight: 'calc(100vh - 50px)' }}>
        <Modal.Header closeButton>
          {page === 1 && <Modal.Title>Schedule a Social Media Post</Modal.Title>}
          {page === 2 && <Modal.Title>Upload Post Media</Modal.Title>}
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', flexDirection: 'column', maxHeight: 'calc(80vh - 100px)', overflowY: 'auto' }}>
          {page === 1 && (
            <Form>
              <Form.Group>
                <Form.Label>Date and Time</Form.Label>
                <Form.Control
                  type="datetime-local"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Platform</Form.Label>
                <Form.Control
                  as="select"
                  value={platform}
                  onChange={(e) => setPlatform(e.target.value)}
                >
                  <option value="">Select a platform</option>
                  <option value="Instagram" disabled={!InstagramVerified}>Instagram</option>
                  <option value="Twitter" disabled={!TwitterVerified}>Twitter</option>
                  <option value="Tiktok" disabled={!TiktokVerified}>Tiktok</option>
                </Form.Control>
              </Form.Group>
              {platform === "Tiktok" && (
                <div>
                  <p> Posting to {tiktokUserInfo?.creator_username}</p>
                  <Form.Group>
                    <Form.Label>Post Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your post title"
                      value={post}
                      onChange={(e) => setPost(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group style={{ marginBottom: 10 }}>
                    <Form.Label>Privacy Status</Form.Label>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Branded content visibility cannot be set to private.</Tooltip>}
                      show={isBrandedContent && postVisibility === 'SELF_ONLY'}
                    >
                      <Form.Control
                        as="select"
                        value={postVisibility}
                        onChange={(e) => setPostVisibility(e.target.value)}
                      >
                        {tiktokUserInfo?.privacy_level_options.map((option, index) => (
                          <option key={index} value={option} disabled={isBrandedContent && option === 'SELF_ONLY'}>
                            {option}
                          </option>
                        ))}
                      </Form.Control>
                    </OverlayTrigger>
                    {isBrandedContent && (
                      <Form.Text className="text-muted">
                        Branded content visibility cannot be set to private.
                      </Form.Text>
                    )}
                  </Form.Group>
                  <div>
                    <Form.Check
                      type="switch"
                      id="comment-switch"
                      label="Disable Comments"
                      disabled={tiktokUserInfo?.comment_disabled}
                      onChange={(e) => setCommentDisabled(e.target.checked)}
                      checked={commentDisabled || tiktokUserInfo?.comment_disabled}
                    />
                    <Form.Check
                      type="switch"
                      id="duet-switch"
                      label="Disable Duet"
                      disabled={tiktokUserInfo?.duet_disabled}
                      onChange={(e) => setDuetDisabled(e.target.checked)}
                      checked={duetDisabled || tiktokUserInfo?.duet_disabled}
                    />
                    <Form.Check
                      type="switch"
                      id="stitch-switch"
                      label="Disable Stitch"
                      disabled={tiktokUserInfo?.stitch_disabled}
                      onChange={(e) => setStitchDisabled(e.target.checked)}
                      checked={stitchDisabled || tiktokUserInfo?.stitch_disabled}
                    />
                    <h5>Disclose video content</h5>
                    <Form.Group controlId="disclosureToggle">
                      <Form.Check
                        type="switch"
                        label="Turn on to disclose that this video promotes goods or services in exchange for something of value. Your video could promote yourself, a third party, or both"
                        checked={isDisclosureEnabled}
                        onChange={handleDisclosureToggle}
                      />
                    </Form.Group>
                    {isDisclosureEnabled ? (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: '#e0f7fa', // Light blue background
                            borderRadius: '8px', // Rounded corners
                            padding: '8px', // Padding inside the box
                            marginTop: '16px', // Margin top for spacing
                          }}
                        >
                          <InfoIcon sx={{ marginRight: '8px', color: '#00796b' }} /> {/* Info icon with margin and color */}
                          <Typography variant="body2" color="textSecondary">
                            {publishDeclaration + " This cannot be changed once your video is posted."}
                          </Typography>
                        </Box>
                        <Form.Group controlId="brandToggle">
                          <Form.Check
                            type="checkbox"
                            label="Your brand"
                            checked={isBrand}
                            onChange={handleBrandToggle}
                          />
                          <Form.Text className="text-muted">
                            You are promoting yourself or your own business. This video will be classified as Brand Organic.
                          </Form.Text>
                        </Form.Group>
                        {postVisibility === 'SELF_ONLY' ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Visibility for branded content can&apos;t be private.</Tooltip>}
                          >
                            <div>
                              <Form.Group controlId="brandedContentToggle">
                                <Form.Check
                                  type="checkbox"
                                  label="Branded content"
                                  checked={isBrandedContent}
                                  onChange={handleBrandedContentToggle}
                                  disabled={postVisibility === 'SELF_ONLY'}
                                />
                                <Form.Text className="text-muted">
                                  You are promoting another brand or a third party. This video will be classified as Branded Content.
                                </Form.Text>
                              </Form.Group>
                            </div>
                          </OverlayTrigger>
                        ) : (
                          <Form.Group controlId="brandedContentToggle">
                            <Form.Check
                              type="checkbox"
                              label="Branded content"
                              checked={isBrandedContent}
                              onChange={handleBrandedContentToggle}
                            />
                            <Form.Text className="text-muted">
                              You are promoting another brand or a third party. This video will be classified as Branded Content.
                            </Form.Text>
                          </Form.Group>
                        )}
                        <Form.Text className="text-muted">
                          {declarationMessage}
                        </Form.Text>
                      </>
                    ) : (
                      <Form.Text className="text-muted">
                        {declarationMessage}
                      </Form.Text>
                    )}
                  </div>
                </div>
              )}
            </Form>
          )}
          {page === 3 && (
            <div>
              <h2>Review Post</h2>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#e0f7fa', // Light blue background
                  borderRadius: '8px', // Rounded corners
                  padding: '8px', // Padding inside the box
                  marginTop: '16px', // Margin top for spacing
                }}
              >
                <InfoIcon sx={{ marginRight: '8px', color: '#00796b' }} /> {/* Info icon with margin and color */}
                <Typography variant="body2" color="textSecondary">
                  Once published, it may take a few minutes for content to process and be visible on your profile.
                </Typography>
              </Box>
              <p>Post: {post}</p>
              <p>Date: {date}</p>
              <p>Platform: {platform}</p>
              <p>File: {selectedFiles[0].name}</p>
              <video controls src={selectedFiles[0].url} alt={selectedFiles[0].name} style={{ width: '100%', height: 'auto' }} />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
          {page === 1 && (
            <>
              {platform === 'Tiktok' && isPublishDisabled ? (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>You need to indicate if your content promotes yourself, a third party, or both.</Tooltip>}
                >
                  <div>
                    <Button variant="primary" onClick={null} disabled={isPublishDisabled}>
                      Next
                    </Button>
                  </div>
                </OverlayTrigger>
              ) : (
                <Button variant="primary" onClick={handleNextPage}>
                  Next
                </Button>
              )}
            </>
          )}
          {page === 2 && (
            <>
              <Button variant="secondary" onClick={handlePreviousPage}>
                Back
              </Button>
              {platform === 'Tiktok' ? (
                <Button variant="primary" onClick={handleNextPage}>
                  Next
                </Button>
              ) : (
                <Button variant="primary" onClick={handleSubmit}>
                  Schedule Post
                </Button>
              )}
            </>
          )}
          {page === 3 && (
            <>
              <Button variant="secondary" onClick={handlePreviousPage}>
                Back
              </Button>
              <Button variant="primary" onClick={handleSubmit} disabled={isPublishDisabled}>
                Schedule Post
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      <WarningModal
        show={openWarning}
        handleClose={() => setOpenWarning(false)}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "black",
        }}
      >
        {/* <h2>{item}</h2> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: 20,
            width: "90%",
          }}
        >
          <Button onClick={downloadCampaignMaterials}>
            Download Campaign Materials
          </Button>
          {/* <Button variant="success" onClick={handleCopyClick}>
            Copy referral link
          </Button> */}
          <input
            type="file"
            ref={fileInput}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <Button
            onClick={() => {
              openModal();
            }}
          >
            +
          </Button>
        </div>
        <Card style={{ width: "90%" }}>
          <p>
            <b>Campaign Name:</b> {item?.title}
          </p>
          {/* <p>{item.status}</p> */}
          <p>
            <b>Description:</b> {item?.campaignId?.description}
          </p>
          <Text>
            <b>Start Date:</b>
            {item.campaignId?.startDate &&
              new Date(Number(item.campaignId?.startDate))
                .toISOString()
                .slice(0, 10)}
          </Text>
          <Text>
            <b>End Date:</b>
            {item.campaignId?.endDate &&
              new Date(Number(item.campaignId?.endDate))
                .toISOString()
                .slice(0, 10)}
          </Text>
          {/* <p>{item.influencer}</p>
        <p>{item.influencerEmail}</p> */}
          <p>
            <b>Platform:</b> {item?.platforms.join(', ')}
          </p>
          <p>
            <b>Therapy Area:</b> {item?.studyId?.condition.join(', ')}
          </p>
          <p>
            <b>Posting Instructions:</b> {item?.postingInstructions}
          </p>
        </Card>
      </div>
    </div>
  );
}

export default ActiveCampaigns;
