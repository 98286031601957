import { gql, useQuery } from "@apollo/client";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useRownd } from "@rownd/react";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { usePlacesWidget } from "react-google-autocomplete";
import { ToastContainer, toast } from "react-toastify";
import "../../App.css";
import { PatientHeader } from "../../components/Menus";
import { useUserContext } from "../../providers/UserContext";

const GET_USER = gql`
  query getUser {
    getMyProfile {
      _id
      firstName
      lastName
      phoneNumber
      email
      zipCode
      platforms
      interestedConditions {
        id
        name
      }
      dateOfBirth
      city
      howDidYouHear
      gender
      raceEthnicity
      medications
      rowndId
    }
  }
`;

const CONDITIONS_QUERY = gql`
  query GetConditions {
    conditions {
      name
      id
    }
  }
`;

//use fragment here? look up downside of fragment use
const UPDATE_USER = gql`
  mutation updateProfile(
    $firstName: String
    $lastName: String
    $email: String
    $phoneNumber: String
    $zipCode: String
    $platforms: [Platform]
    $interestedConditions: [ConditionInput]
    $dateOfBirth: String
    $city: String
    $howDidYouHear: String
    $gender: String
    $raceEthnicity: [String]
    $medications: String
  ) {
    updateProfile(
      userInput: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        zipCode: $zipCode
        phoneNumber: $phoneNumber
        platforms: $platforms
        interestedConditions: $interestedConditions
        dateOfBirth: $dateOfBirth
        city: $city
        raceEthnicity: $raceEthnicity
        gender: $gender
        medications: $medications
        howDidYouHear: $howDidYouHear
      }
    ) {
      _id
      firstName
      lastName
      phoneNumber
      email
      zipCode
      platforms
      interestedConditions {
        id
        name
      }
      dateOfBirth
      city
      howDidYouHear
      gender
      raceEthnicity
      medications
    }
  }
`;

const races = [
  "American Indian/Alaska Native",
  "Asian",
  "Black or African American",
  "Hispanic or Latinx",
  "Native Hawaiian or other Pacific Islander",
  "White",
];

const Profile = () => {
  const inputRef = useRef(null);
  const { saveUser, user } = useUserContext();
  const [country, setCountry] = useState("us");

  const { is_authenticated } = useRownd();
  const [userProfile, setUserProfile] = useState({
    firstName: user?.firstName? user.firstName : "",
    lastName: user?.lastName? user.lastName : "",
    dateOfBirth: user?.dateOfBirth? user.dateOfBirth : "",
    city: user?.city? user.city : "",
    phoneNumber: user?.phoneNumber? user.phoneNumber : "",
    gender: user?.gender ? user.gender : "",
    raceEthnicity: user?.raceEthnicity ? user.raceEthnicity : [],
    interestedConditions: user?.interestedConditions ? user?.interestedConditions : [],
    howDidYouHear: user?.howDidYouHear ? user.howDidYouHear : "",
    medications: user?.medications ? user.medications : "",
    // platforms: user?.platforms ? user.platforms : null,
    zipCode: user?.zipCode ? user.zipCode : "",
  });

  const [googleAddress, setGoogleAddress] = useState("");

  console.log("userProfile", userProfile);

  const [conditions, setConditions] = useState([]);
  const [checkedItems, setCheckedItems] = useState(
    Array(conditions.length).fill(false)
  );
  const { ref: materialRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE,
    onPlaceSelected: (place) => {
      console.log('place selected', place);
      console.log(('userProfile', userProfile));
      // setUserProfile({ ...userProfile, city: place.formatted_address });
      setGoogleAddress(place.formatted_address);
    },
    inputAutocompleteValue: "country",
    options: {
      types: ['(cities)'], // Restrict to cities
      componentRestrictions: { country },
    },
  });

  console.log("patient profile", userProfile);

  useEffect(() => {
    if (googleAddress) {
      setUserProfile({ ...userProfile, city: googleAddress });
    }
  }, [googleAddress]);

  // const { loading, error, data } = useQuery(GET_USER, {
  //   skip: !is_authenticated,
  // });
  const {
    loading: conditionsLoading,
    error: conditionsError,
    data: conditionsData,
  } = useQuery(CONDITIONS_QUERY);
  // const [updateUser] = useMutation(UPDATE_USER, {
  //   variables: {
  //     ...userProfile,
  //   },
  //   refetchQueries: [{ query: GET_USER }],
  // });

  // useEffect(() => {
  //   if (data) {
  //     setUserProfile({
  //       ...data.getMyProfile,
  //       city: data.getMyProfile.city || "",
  //       raceEthnicity: data.getMyProfile.raceEthnicity || [],
  //     });
  //   }
  // }, [data]);

  useEffect(() => {
    if (user) {
      setUserProfile({
        firstName: user?.firstName? user.firstName : "",
        lastName: user?.lastName? user.lastName : "",
        dateOfBirth: user?.dateOfBirth? user.dateOfBirth : "",
        city: user?.city? user.city : "",
        phoneNumber: user?.phoneNumber? user.phoneNumber : "",
        gender: user?.gender ? user.gender : "",
        raceEthnicity: user?.raceEthnicity ? user.raceEthnicity : [],
        interestedConditions: user?.interestedConditions ? user?.interestedConditions : [],
        howDidYouHear: user?.howDidYouHear ? user.howDidYouHear : "",
        medications: user?.medications ? user.medications : "",
        // platforms: user?.platforms ? user.platforms : [],
        zipCode: user?.zipCode ? user.zipCode : "",
      });
    }
  }, [user]);

  useEffect(() => {
    if (conditionsData) {
      setConditions(conditionsData?.conditions);
      setCheckedItems(Array(conditionsData?.conditions.length).fill(false));
    }
  }, [conditionsData, conditionsLoading]);

  const handleInputChange = (event) => {
    console.log("event", event.target.name , event.target.value);


    setUserProfile({
      ...userProfile,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // updateUser();
    try {
      console.log("userProfile", userProfile);
      saveUser({
        variables: {
          ...userProfile,
        },
      });
      toast.success("Profile updated successfully");
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSingleCheck = (index) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = !newCheckedItems[index];
    setCheckedItems(newCheckedItems);
    //also add or remove item from list of interested conditions in user profile

    if (newCheckedItems[index]) {
      setUserProfile({
        ...userProfile,
        interestedConditions: [
          ...userProfile.interestedConditions,
          conditions[index],
        ],
      });
    } else {
      setUserProfile({
        ...userProfile,
        interestedConditions: userProfile.interestedConditions.filter(
          (condition) => condition.id !== conditions[index].id
        ),
      });
    }
  };

  const handleSelectAll = () => {
    const areAllItemsChecked = checkedItems.every(Boolean);
    setCheckedItems(Array(conditions.length).fill(!areAllItemsChecked));
    // also add or remove all items from list of interested conditions in user profile

    if (!areAllItemsChecked) {
      setUserProfile({ ...userProfile, interestedConditions: conditions });
    } else {
      setUserProfile({ ...userProfile, interestedConditions: [] });
    }
  };

  const handleMultiSelectChange = (event) => {
    const value = event.target.value;
    setUserProfile({ ...userProfile, raceEthnicity: value });
  };

  const handleConditionChange = (event) => {
    const conditionId = event.target.value;
    const checked = event.target.checked;

    // Create a new array for the checked items
    let newCheckedItems = [...checkedItems];

    // Find the index of the condition in the conditions array
    const index = conditions.findIndex(
      (condition) => condition.id === conditionId
    );

    // Update the checked status of the condition
    newCheckedItems[index] = checked;

    // Update the state with the new checked items array
    setCheckedItems(newCheckedItems);

    // If the condition is checked, add it to the interested conditions
    // If it's unchecked, remove it from the interested conditions
    if (checked) {
      setUserProfile({
        ...userProfile,
        interestedConditions: [
          ...userProfile.interestedConditions,
          conditions[index],
        ],
      });
    } else {
      setUserProfile({
        ...userProfile,
        interestedConditions: userProfile.interestedConditions.filter(
          (condition) => condition.id !== conditionId
        ),
      });
    }
  };

  return (
    <div className="App">
      <ToastContainer />
      <PatientHeader title={"Update Profile"} />
      <div className="PatientApp-body">
        <Form
          onSubmit={handleSubmit}
          style={{
            backgroundColor: "#fff",
            padding: 50,
            width: "80%",
            borderRadius: 10,
            border: "1px solid rgba(3, 46, 94, 0.06)",
          }}
        >
          <div className="input-row">
            <div className="input-group">
              <TextField
                type="text"
                name="firstName"
                variant="outlined"
                label="First Name"
                value={userProfile.firstName}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <TextField
                type="text"
                name="lastName"
                variant="outlined"
                label="Last Name"
                value={userProfile.lastName}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="input-row">
            <div className="input-group">
              <TextField
                type="date"
                name="dateOfBirth"
                variant="outlined"
                label="Date of Birth"
                value={userProfile.dateOfBirth}
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="input-group">
              <TextField
                fullWidth
                name="city"
                label="City"
                color="secondary"
                variant="outlined"
                value={userProfile?.city}
                inputRef={materialRef}
                onChange={handleInputChange}
              />
            </div>
          </div>
          {/* <div className="input-row">
            <div className="input-group">
              <Form.Label>
                Display Name:
                <Form.Control
                  type="text"
                  name="displayName"
                  value={userProfile.displayName}
                  onChange={handleInputChange}
                />
              </Form.Label>
            </div>
            <div className="input-group">
              <Form.Label>
                Username:
                <Form.Control
                  type="text"
                  name="userName"
                  value={userProfile.userName}
                  onChange={handleInputChange}
                />
              </Form.Label>
            </div>
          </div> */}
          <div className="input-row">
            <div className="input-group">
              <TextField
                type="tel"
                name="phoneNumber"
                variant="outlined"
                label="Phone Number"
                value={userProfile.phoneNumber}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <FormControl>
                <InputLabel id="demo-mutiple-checkbox-label">
                  Interested Conditions
                </InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={userProfile.interestedConditions}
                  onChange={handleConditionChange}
                  renderValue={(selected) => {
                    console.log("selected", selected);
                    return selected
                      .map((condition) => condition.name)
                      .join(", ");
                  }}
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={checkedItems.every(Boolean)}
                      onChange={handleSelectAll}
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {conditions.map((condition, id) => (
                    <MenuItem key={id} value={condition.id}>
                      <Checkbox
                        checked={checkedItems[id]}
                        onChange={() => handleSingleCheck(id)}
                      />
                      <ListItemText primary={condition.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="input-row">
            <div className="input-group">
              <FormControl>
                <InputLabel
                  id="race-label"
                  style={{ marginLeft: 20 }}
                  shrink={userProfile?.raceEthnicity?.length > 0}
                >
                  Race/Ethnicity
                </InputLabel>
                <Select
                  required
                  error={!userProfile?.raceEthnicity?.length}
                  helperText={
                    !userProfile?.raceEthnicity?.length
                      ? "Race/Ethnicity is required"
                      : ""
                  }
                  labelId="race-label"
                  id="race"
                  variant="outlined"
                  multiple
                  value={userProfile?.raceEthnicity}
                  onChange={handleMultiSelectChange}
                  name="raceEthnicity"
                >
                  {races.map((race) => (
                    <MenuItem key={race} value={race}>
                      {race}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="input-group">
              <FormControl>
                <InputLabel id="race-label" style={{ marginLeft: 20 }}>
                  Sex (Assigned at birth)
                </InputLabel>
                <Select
                  required
                  error={!userProfile?.gender?.length}
                  helperText={
                    !userProfile?.gender?.length
                      ? "Sex (Assigned at birth) is required"
                      : ""
                  }
                  labelId="race-label"
                  id="race"
                  variant="outlined"
                  value={userProfile?.gender}
                  onChange={handleInputChange}
                  name="gender"
                >
                  {["Male", "Female"].map((race) => (
                    <MenuItem key={race} value={race}>
                      {race}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="input-row-full">
            <FormControl>
              <InputLabel id="demo-simple-select-label">
                How did you hear about us?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={userProfile.howDidYouHear}
                onChange={handleInputChange}
                name="howDidYouHear"
                variant="outlined"
              >
                <MenuItem value="facebook">Facebook</MenuItem>
                <MenuItem value="instagram">Instagram</MenuItem>
                <MenuItem value="twitter">Twitter</MenuItem>
                <MenuItem value="google">Google</MenuItem>
                <MenuItem value="friend">Friend</MenuItem>
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="input-row-full">
            <TextField
              multiline
              name="medications"
              variant="outlined"
              label="Please list any medications you are on"
              value={userProfile.medications}
              onChange={handleInputChange}
              rows={4}
            />
          </div>
          <Button type="submit" style={{ backgroundColor: "#009EC5" }}>
            Update Profile
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Profile;
