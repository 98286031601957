import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListIcon from "@mui/icons-material/List";
import {
  Badge,
  Button,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Roles } from "../constants/user";
import { useUserContext } from "../providers/UserContext";

const steps = [
  {
    title: "Agree to Contract Terms",
    description:
      "Please review and agree to the contract terms to proceed. This ensures a mutual understanding of responsibilities.",
    actionText: "Review Terms",
    link: "/contract-terms", // Assuming you have a route set up for this
  },
  {
    title: "Watch an Onboarding Video",
    description:
      "Watch our onboarding video to familiarize yourself with our platform and its key features.",
    actionText: "Watch Video",
    link: "/onboarding-video", // Assuming you have a route set up for this
  },
  {
    title: "Hipaa Quiz",
    description:
      "Complete a short quiz to confirm your understanding of our platform and its policies.",
    actionText: "Take Quiz",
    link: "/quiz", // Assuming you have a route set up for this
  },
];

const OnboardingChecklist = ({
  openTerms,
  setOpenTerms,
  openVideo,
  setOpenVideo,
  onenQuiz,
  setOpenQuiz,
}) => {
  const { user } = useUserContext();

  const [open, setOpen] = useState(false);
  const [openChecklist, setOpenChecklist] = useState(false);
  const [expandedStep, setExpandedStep] = useState(null);
  const [incompleteSteps, setIncompleteSteps] = useState(3);

  const handleToggle = () => {
    setOpen(!open);
  };

  const toggleOpen = () => setOpen(!open);

  const toggleChecklist = () => setOpenChecklist(!openChecklist);

  const handleExpandClick = (index) => {
    setExpandedStep(expandedStep === index ? null : index); // Toggle or close the item
  };

  if (!user) {
    return null; // Do not render if user is not available
  }
  const { roles: userRole, onboardingProgress } = user;

  //   useEffect(() => {
  //     // Fetch onboarding progress from backend if needed
  //   }, []);

  console.log("userRole in checklist", user, onboardingProgress);

  if (!userRole.includes(Roles.Dhi) || user?.dhiOnboardingComplete) {
    return null; // Do not render if user role doesn't match or onboarding is complete
  }

  const completeStep = (step) => {
    console.log("Completing step", step);
    // updateOnboardingProgress({
    //   variables: {
    //     step,
    //   },
    // });
    // Call backend API to update progress
  };

  // Replace with actual count of incomplete steps

  // useEffect(() => {
  //   let steps = 3;
  //   if (onboardingProgress?.termsAgreed) {
  //     steps--;
  //   }
  //   if (onboardingProgress?.videoWatched) {
  //     steps--;
  //   }
  //   if (onboardingProgress?.quizPassed) {
  //     steps--;
  //   }
  //   setIncompleteSteps(steps);
  // }, [onboardingProgress]);

  const stepCompleted = (index) => {
    let checked = false;
    if (index === 0 && onboardingProgress?.termsAgreed) {
      checked = true; // Replace with actual check
    }
    if (index === 1 && onboardingProgress?.videoWatched) {
      checked = true; // Replace with actual check
    }
    if (index === 2 && onboardingProgress?.quizPassed) {
      checked = true; // Replace with actual check
    }

    return checked;
  };

  console.log("incompleteSteps", incompleteSteps);

  return (
    <Card
      sx={{
        position: "fixed",
        bottom: 20,
        right: 20,
        maxWidth: 300,
        overflow: "visible",
      }}
    >
      {!openChecklist ? (
        <Badge badgeContent={incompleteSteps} color="error">
          <IconButton onClick={toggleChecklist} sx={{ color: "primary.main" }}>
            <ListIcon />
          </IconButton>
        </Badge>
      ) : (
        <>
          <CardContent>
            <Typography
              variant="h5"
              component="div"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Onboarding Checklist
              <IconButton
                onClick={toggleChecklist}
                sx={{ color: "primary.main" }}
              >
                <CloseIcon />
              </IconButton>
            </Typography>
          </CardContent>
          <List>
            {steps.map((step, index) => (
              <React.Fragment key={index}>
                <ListItem
                  button
                  onClick={() => handleExpandClick(index)}
                  disablePadding
                >
                  <ListItemIcon style={{ marginLeft: 5 }}>
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      disableRipple
                      disabled
                      checked={stepCompleted(index)}
                    />
                  </ListItemIcon>
                  <ListItemText primary={step.title} />
                  {expandedStep === index ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </ListItem>
                <Collapse
                  in={expandedStep === index}
                  timeout="auto"
                  unmountOnExit
                >
                  <CardContent>
                    <Typography variant="body2">{step.description}</Typography>
                    <Button
                      size="small"
                      disabled={stepCompleted(index)}
                      onClick={() => {
                        index === 0
                          ? setOpenTerms(true)
                          : index === 1
                          ? setOpenVideo(true)
                          : setOpenQuiz(true);
                      }}
                      sx={{ marginTop: "10px" }}
                    >
                      {step.actionText}
                    </Button>
                  </CardContent>
                </Collapse>
              </React.Fragment>
            ))}
          </List>
        </>
      )}
    </Card>
  );
};

export default OnboardingChecklist;
