import { gql, useMutation, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import { Box, Grid, IconButton, Pagination, Typography } from "@mui/material";
import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CampaignImage from "../../assets/campaigntemp.png";
import { Roles } from "../../constants/user";
import { useUserContext } from "../../providers/UserContext";
import { truncateText } from "../../utilities/format";

const ACTIVATE_CAMPAIGN = gql`
  mutation ActivateCampaign($campaignId: String!) {
    activateCampaign(campaignId: $campaignId) {
      _id
      name
      description
    }
  }
`;

const GET_CAMPAIGNS = gql`
  query GetCampaigns {
    getActiveCampaigns {
      _id
      name
      description
      startDate
      endDate
      platforms
      therapyAreas {
        name
        active
      }
      studyId {
        condition
        }
      postingInstructions
    }
  }
`;

const Image = styled.img`
  height: 200px;
  width: 100%;
  margin-top: 5px;
`;

const tempCampaigns = [
  {
    name: "Campaign 1",
    status: "Active",
    platforms: ["Facebook", "Instagram"],
    therapyAreas: ["Depression", "Anxiety"],
  },
  {
    name: "Campaign 2",
    status: "Active",
    platforms: ["Facebook", "Instagram"],
    therapyAreas: ["Depression", "Anxiety"],
  },
  {
    name: "Campaign 3",
    status: "Active",
    platforms: ["Facebook", "Instagram"],
    therapyAreas: ["Depression", "Anxiety"],
  },
  {
    name: "Campaign 4",
    status: "Active",
    platforms: ["Facebook", "Instagram"],
    therapyAreas: ["Depression", "Anxiety"],
  },
  {
    name: "Campaign 5",
    status: "Active",
    platforms: ["Facebook", "Instagram"],
    therapyAreas: ["Depression", "Anxiety"],
  },
  {
    name: "Campaign 6",
    status: "Active",
    platforms: ["Facebook", "Instagram"],
    therapyAreas: ["Depression", "Anxiety"],
  },
  {
    name: "Campaign 7",
    status: "Active",
    platforms: ["Facebook", "Instagram"],
    therapyAreas: ["Depression", "Anxiety"],
  },
  {
    name: "Campaign 8",
    status: "Active",
    platforms: ["Facebook", "Instagram"],
    therapyAreas: ["Depression", "Anxiety"],
  },
  {
    name: "Campaign 9",
    status: "Active",
    platforms: ["Facebook", "Instagram"],
    therapyAreas: ["Depression", "Anxiety"],
  },
];

function CampaignMarket() {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { data, loading, error } = useQuery(GET_CAMPAIGNS, {
    skip: !user,
  });
  const [activateCampaign] = useMutation(ACTIVATE_CAMPAIGN, {
    refetchQueries: [{ query: GET_CAMPAIGNS }],
  });
  console.log("data", data);

  const [campaigns, setCampaigns] = useState([]);
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [page, setPage] = useState(1);
  const itemsPerPage = 9;

  const referralCode = "Cameron54010";

  const handleCopy = () => {
    navigator.clipboard.writeText(referralCode);
    alert("Referral code copied to clipboard!");
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: "Invite Your Friends",
        text: `Use my referral code: ${referralCode}`,
        url: window.location.href,
      });
    } else {
      alert("Share not supported on this browser.");
    }
  };

  useEffect(() => {
    document.title = "DHI Dashboard";
  }, []);

  useEffect(() => {
    if (!user) return;
    if (!user?.firstName) {
      return navigate("/dhi/profile");
    }
    console.log("user on dhi dashboard", user);
    const roles = user?.roles;
    if (roles && !roles.includes(Roles.Dhi)) {
      // setError("Your role is not dhi");
    }
  }, [navigate, user]);
  useEffect(() => {
    if (!loading && !error) {
      setCampaigns(data?.getActiveCampaigns || []);
    }
  }, [loading, error, data]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleCardClick = (campaign) => {
    setSelectedCampaign(campaign);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleActivate = async () => {
    // activate the campaign for the dhi
    const res = await activateCampaign({
      variables: {
        campaignId: selectedCampaign._id,
      },
    });

    if (res) {
      // show success message
      handleCloseModal();
    } else {
      // show error message
    }
  };

  const displaySocialIcons = (platformName) => {
    switch (platformName) {
      case "Facebook":
        return (
          <Box
            style={{
              borderRadius: "20%",
              border: "1px solid #009EC5",
              padding: "5px",
              marginRight: "5px",
            }}
          >
            <FontAwesomeIcon
              icon={["fab", "facebook"]}
              style={{
                alignSelf: "center",
                fontSize: "25px",
                color: "#009EC5",
              }}
            />
          </Box>
        );
      case "Instagram":
        return (
          <Box
            style={{
              borderRadius: "20%",
              border: "1px solid #009EC5",
              padding: "5px",
              marginRight: "5px",
            }}
          >
            <FontAwesomeIcon
              icon={["fab", "instagram"]}
              style={{
                alignSelf: "center",
                fontSize: "25px",
                color: "#009EC5",
              }}
            />
          </Box>
        );
      case "Twitter":
        return (
          <Box
            style={{
              borderRadius: "20%",
              border: "1px solid #009EC5",
              padding: "5px",
              marginRight: "5px",
            }}
          >
            <FontAwesomeIcon
              icon={["fab", "twitter"]}
              style={{
                alignSelf: "center",
                fontSize: "25px",
                color: "#009EC5",
              }}
            />
          </Box>
        );
      case "Tiktok":
        return (
          <Box
            style={{
              borderRadius: "20%",
              border: "1px solid #009EC5",
              padding: "5px",
              marginRight: "5px",
            }}
          >
            <FontAwesomeIcon
              icon={["fab", "tiktok"]}
              style={{
                alignSelf: "center",
                fontSize: "25px",
                color: "#009EC5",
              }}
            />
          </Box>
        );
      default:
        return (
          <Box
            style={{
              borderRadius: "20%",
              border: "1px solid #009EC5",
              padding: "5px",
              marginRight: "5px",
            }}
          >
            <FontAwesomeIcon
              icon={["fab", "facebook"]}
              style={{ alignSelf: "center", fontSize: "25px", color: "#000" }}
            />
          </Box>
        );
    }
  };

  return (
    <div className="App">
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedCampaign?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <b>Description:</b> {selectedCampaign?.description}
          </p>
          <p>
            <b>Start Date:</b>{" "}
            {new Date(Number(selectedCampaign?.startDate)).toDateString()}
          </p>
          <p>
            <b>End Date:</b>{" "}
            {new Date(Number(selectedCampaign?.endDate)).toDateString()}
          </p>
          <p>
            <b>Platforms:</b>{" "}
            {Array.isArray(selectedCampaign?.platforms)
              ? selectedCampaign?.platforms.join(", ")
              : selectedCampaign?.platforms}
          </p>
          <p>
            <b>Therapy Areas:</b>{" "}
            {Array.isArray(selectedCampaign?.therapyAreas) && selectedCampaign?.therapyAreas.length > 0
              ? selectedCampaign?.therapyAreas.join(", ")
              : selectedCampaign?.studyId.condition.join("; ")}
              
          </p>
          <p>
            <b>Instructions:</b> {selectedCampaign?.postingInstructions}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleActivate}>
            Activate
          </Button>
        </Modal.Footer>
      </Modal>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "black",
          marginTop: 20,
        }}
      >
         <div
            style={{
              marginLeft: 50,
              marginRight: 50,
              width: "90%",
            }}
          >
             <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              p={1}
              width={"100%"}
              borderRadius={2}
            >
              <Typography variant="h5" color="#032E5E" fontWeight={"bold"}>
                Available Campaigns
              </Typography>
              {/* <Box
                display="flex"
                bgcolor="#00A5CF"
                flexDirection={"row"}
                p={1}
                borderRadius={2}
              >
                <Box
                  display="flex"
                  bgcolor="#00A5CF"
                  flexDirection={"column"}
                  textAlign={"left"}
                >
                  <Typography variant="body2" color="white" fontWeight={"bold"}>
                    Invite Your Friends
                  </Typography>
                  <Typography variant="body2" color="white">
                    Code: {referralCode}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <IconButton onClick={handleCopy} sx={{ color: "white" }}>
                    <ContentCopyIcon />
                  </IconButton>
                  <IconButton onClick={handleShare} sx={{ color: "white" }}>
                    <ShareIcon />
                  </IconButton>
                </Box>
              </Box> */}
            </Box>
        {campaigns && campaigns.length > 0 ? (
            <Grid container spacing={4}>
              {campaigns.map((item, index) => (
                <Grid item xs={12} sm={8} md={4} key={index}>
                  <Card
                    style={{
                      width: "100%",
                      backgroundColor: "rgba(0, 158,197, .1)",
                      textAlign: "left",
                    }}
                    className="tier-card"
                    onClick={
                      () => handleCardClick(item)
                      // navigate(`/campaign-details/${item._id}`)
                    }
                  >
                    <Image src={CampaignImage} alt="Campaign" />
                    <Typography
                      variant="h5"
                      color="#032E5E"
                      fontWeight={"bold"}
                      style={{ marginBottom: "8px", marginTop: 5 }} // Add margin bottom for spacing
                    >
                      {truncateText(item.name, 100)}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="#032E5E"
                      style={{ marginBottom: "4px" }} // Add margin bottom for spacing
                    >
                      Platforms
                    </Typography>
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      style={{
                        marginBottom: "8px",
                      }}
                    >
                      {item?.platforms.map((platform, index) => (
                        <>{displaySocialIcons(platform)}</>
                      ))}
                    </Box>
                    <Typography
                      variant="h5"
                      color="#032E5E"
                      style={{ marginBottom: "4px" }} // Add margin bottom for spacing
                    >
                      Therapy Areas
                    </Typography>
                    <Typography variant="h5" color="#032E5E">
                      {item.therapyAreas > 0 ? item.therapyAreas.join(", "): item.studyId.condition.join(", ")}
                    </Typography>
                  </Card>
                </Grid>
              ))}
            </Grid>
          
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" width={"100%"} height={"65vh"}>
          </Box>
        )
      }
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          count={Math.ceil(campaigns.length / itemsPerPage)}
          page={page}
          onChange={handlePageChange}
          style={{ marginBottom: 20, alignSelf: "center" }}
        />
      </div>
    </div>
  );
}

export default CampaignMarket;
