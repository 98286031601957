import { gql, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useUserContext } from "../../providers/UserContext";

const ACTIVE_CAMPAIGNS_QUERY = gql`
  query GetCampaigns($filter: String) {
    getMyCampaigns(filter: $filter) {
      id: _id
      title
      status
      startDate
      endDate
      description
      influencer
      platforms
      postingInstructions
      materials {
        url
      }
      campaignId {
        caption
        name
        startDate
        endDate
        therapyAreas
        description
      }
      studyId {
        title
        condition
      }
    }
  }
`;

const Container = styled.div`
  background-color: white;
  height: 500px;
  width: 80%;
  border-radius: 10px;
  padding: 10px;
`;
const InnerContainer = styled.div`
  justify-content: center;
  background-color: lightblue;
  margin: 10px;
  height: 60px;
  align-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
`;
const Input = styled.input`
  display: block;
  margin: 10px auto;
  align-self: center;
  width: 200px;
  height: 30px;
`;
// const Button = styled.button`
//   background-color: blue;
//   border-radius: 10px;
//   height: 50px;
//   width: 100px;
//   margin-top: 50px;
// `;
const Text = styled.p`
  color: white;
  font-size: 24px;
  padding-top: 20px;
`;

const StyledLink = styled(NavLink)`
  margin-right: 15px;
  margin-left: 15px;
  color: #fff;
  text-decoration: none;
  &.${(props) => props.activeClassName} {
    color: blue;
  }
  &:hover {
    color: lightblue;
  }
`;

const Image = styled.img`
  height: 200px;
  width: 100%;
  margin-top: 5px;
`;

const tempCampaigns = [
  {
    name: "Campaign 1",
    status: "Active",
    platforms: ["Facebook", "Instagram"],
    therapyAreas: ["Depression", "Anxiety"],
  },
  {
    name: "Campaign 2",
    status: "Active",
    platforms: ["Facebook", "Instagram"],
    therapyAreas: ["Depression", "Anxiety"],
  },
  {
    name: "Campaign 3",
    status: "Active",
    platforms: ["Facebook", "Instagram"],
    therapyAreas: ["Depression", "Anxiety"],
  },
  {
    name: "Campaign 4",
    status: "Active",
    platforms: ["Facebook", "Instagram"],
    therapyAreas: ["Depression", "Anxiety"],
  },
  {
    name: "Campaign 5",
    status: "Active",
    platforms: ["Facebook", "Instagram"],
    therapyAreas: ["Depression", "Anxiety"],
  },
  {
    name: "Campaign 6",
    status: "Active",
    platforms: ["Facebook", "Instagram"],
    therapyAreas: ["Depression", "Anxiety"],
  },
  {
    name: "Campaign 7",
    status: "Active",
    platforms: ["Facebook", "Instagram"],
    therapyAreas: ["Depression", "Anxiety"],
  },
  {
    name: "Campaign 8",
    status: "Active",
    platforms: ["Facebook", "Instagram"],
    therapyAreas: ["Depression", "Anxiety"],
  },
  {
    name: "Campaign 9",
    status: "Active",
    platforms: ["Facebook", "Instagram"],
    therapyAreas: ["Depression", "Anxiety"],
  },
];

const PerformanceDashboard = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { data, loading, error } = useQuery(ACTIVE_CAMPAIGNS_QUERY, {
    skip: !user,
    variables: { filter: "active" },
  });
  const [campaigns, setCampaigns] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [tabs, setTabs] = useState(["1Y", "1M", "1D"]);
  const [activeTab, setActiveTab] = useState(1);

  const metrics = {
    linkClicks: 0,
    newSignups: 0,
    likes: 0,
    comments: 0,
    views: 0,
    engagementRate: 0,
    shares: 0,
    bookmarks: 0,
    reposts: 0,
    posts: 0,
    facebook: {
      linkClicks: 0,
      signups: 0,
    },
    instagram: {
      linkClicks: 0,
      signups: 0,
    },
    tiktok: {
      linkClicks: 0,
      signups: 0,
    },
    twitter: {
      linkClicks: 0,
      signups: 0,
    },
  };
  

  useEffect(() => {
    document.title = "DHI Performance Dashboard";
  });

  useEffect(() => {
    if (error) {
      console.log(error);
    }
  });

  useEffect(() => {
    if (data) {
      setCampaigns(data.getMyCampaigns);
    }
  }, [data]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleActivate = async () => {
    // activate the campaign for the dhi
    // const res = await activateCampaign({
    //   variables: {
    //     campaignId: selectedCampaign._id,
    //   },
    // });
  };

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const displaySocialIcons = (platformName) => {
    switch (platformName) {
      case "Facebook":
        return (
          <Box
            style={{
              borderRadius: "20%",
              border: "1px solid #009EC5",
              padding: "5px",
              marginRight: "5px",
            }}
          >
            <FontAwesomeIcon
              icon={["fab", "facebook"]}
              style={{
                alignSelf: "center",
                fontSize: "25px",
                color: "#009EC5",
              }}
            />
          </Box>
        );
      case "Instagram":
        return (
          <Box
            style={{
              borderRadius: "20%",
              border: "1px solid #009EC5",
              padding: "5px",
              marginRight: "5px",
            }}
          >
            <FontAwesomeIcon
              icon={["fab", "instagram"]}
              style={{
                alignSelf: "center",
                fontSize: "25px",
                color: "#009EC5",
              }}
            />
          </Box>
        );
      case "Twitter":
        return (
          <Box
            style={{
              borderRadius: "20%",
              border: "1px solid #009EC5",
              padding: "5px",
              marginRight: "5px",
            }}
          >
            <FontAwesomeIcon
              icon={["fab", "twitter"]}
              style={{
                alignSelf: "center",
                fontSize: "25px",
                color: "#009EC5",
              }}
            />
          </Box>
        );
      case "Tiktok":
        return (
          <Box
            style={{
              borderRadius: "20%",
              border: "1px solid #009EC5",
              padding: "5px",
              marginRight: "5px",
            }}
          >
            <FontAwesomeIcon
              icon={["fab", "tiktok"]}
              style={{
                alignSelf: "center",
                fontSize: "25px",
                color: "#009EC5",
              }}
            />
          </Box>
        );
      default:
        return (
          <Box
            style={{
              borderRadius: "20%",
              border: "1px solid #009EC5",
              padding: "5px",
              marginRight: "5px",
            }}
          >
            <FontAwesomeIcon
              icon={["fab", "facebook"]}
              style={{ alignSelf: "center", fontSize: "25px", color: "#000" }}
            />
          </Box>
        );
    }
  };

  return (
    <div className="App">
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedCampaign?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <b>Description:</b> {selectedCampaign?.description}
          </p>
          <p>
            <b>Start Date:</b>{" "}
            {new Date(Number(selectedCampaign?.startDate)).toDateString()}
          </p>
          <p>
            <b>End Date:</b>{" "}
            {new Date(Number(selectedCampaign?.endDate)).toDateString()}
          </p>
          <p>
            <b>Platforms:</b>{" "}
            {Array.isArray(selectedCampaign?.platforms)
              ? selectedCampaign?.platforms.join(", ")
              : selectedCampaign?.platforms}
          </p>
          <p>
            <b>Therapy Areas:</b>{" "}
            {Array.isArray(selectedCampaign?.therapyAreas)
              ? selectedCampaign?.therapyAreas.join(", ")
              : selectedCampaign?.therapyAreas}
          </p>
          <p>
            <b>Instructions:</b> {selectedCampaign?.postingInstructions}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleActivate}>
            Activate
          </Button>
        </Modal.Footer>
      </Modal>

      <div
        style={{
          backgroundColor: "#F8F9FC",
          color: "#000",
          margin: "0px",
          minHeight: "80vh",
          paddingLeft: 100,
          paddingRight: 100,
          textAlign: "left",
          paddingBottom: 50,
        }}
      >
        <Text
          style={{ color: "#032E5E", fontWeight: "bold", textAlign: "left" }}
        >
          Influencer Analytics
        </Text>

        <div>
          <Card style={{ minHeight: 700 }}>
            <Box
              display="flex"
              flexDirection="row"
              style={{ marginBottom: "8px", justifyContent: "space-between" }}
            >
              <Typography
                variant="h5"
                color="#000"
                fontWeight={"bold"}
                style={{ marginBottom: "8px", marginTop: 5 }}
              >
                Overview
              </Typography>
              <Box sx={{ width: "15%" }}>
                <Tabs
                  value={activeTab}
                  onChange={handleChange}
                  variant="fullWidth"
                  textColor="inherit"
                  indicatorColor="primary"
                  sx={{
                    backgroundColor: "rgba(3, 46, 94, 0.06)",
                    borderRadius: "20px",
                    minHeight: "20px",
                    textDecoration: "none",
                    "& .MuiTabs-indicator": {
                      display: "none",
                    },
                  }}
                >
                  {tabs.map((tab, index) => (
                    <Tab
                      key={index}
                      label={tab}
                      sx={{
                        color: "#000",
                        minHeight: "20px",
                        width: "20px",
                        minWidth: "20px",
                        "&.Mui-selected": {
                          color: "#fff",
                          backgroundColor: "#044389",
                          textDecoration: "none",
                        },
                        borderRadius: "20px",
                      }}
                    />
                  ))}
                </Tabs>
              </Box>
            </Box>
            {/* Insert two rows of 5 column cards that display metrics */}
            <Box display="flex" flexDirection="row">
              <Card
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: 5,
                  borderColor: "rgba(0, 158, 197, 0.2)",
                }}
                className="tier-card"
              >
                <Typography
                  variant="h5"
                  color="#032E5E"
                  fontWeight={"bold"}
                  style={{ marginBottom: "8px", marginTop: 5 }}
                >
                  Link Clicks
                </Typography>
                <Typography variant="h4" color="#032E5E">
                  {metrics.linkClicks}
                </Typography>
              </Card>
              <Card
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: 5,
                  borderColor: "rgba(0, 158, 197, 0.2)",
                }}
                className="tier-card"
              >
                <Typography
                  variant="h5"
                  color="#032E5E"
                  fontWeight={"bold"}
                  style={{ marginBottom: "8px", marginTop: 5 }}
                >
                  New Signups
                </Typography>
                <Typography variant="h4" color="#032E5E">
                  {metrics.newSignups}
                </Typography>
              </Card>
              <Card
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: 5,
                  borderColor: "rgba(0, 158, 197, 0.2)",
                }}
                className="tier-card"
              >
                <Typography
                  variant="h5"
                  color="#032E5E"
                  fontWeight={"bold"}
                  style={{ marginBottom: "8px", marginTop: 5 }}
                >
                  Likes
                </Typography>
                <Typography variant="h4" color="#032E5E">
                  {metrics.likes}
                </Typography>
              </Card>
              <Card
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: 5,
                  borderColor: "rgba(0, 158, 197, 0.2)",
                }}
                className="tier-card"
              >
                <Typography
                  variant="h5"
                  color="#032E5E"
                  fontWeight={"bold"}
                  style={{ marginBottom: "8px", marginTop: 5 }}
                >
                  Comments
                </Typography>
                <Typography variant="h4" color="#032E5E">
                  {metrics.comments}
                </Typography>
              </Card>
              <Card
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: 5,
                  borderColor: "rgba(0, 158, 197, 0.2)",
                }}
                className="tier-card"
              >
                <Typography
                  variant="h5"
                  color="#032E5E"
                  fontWeight={"bold"}
                  style={{ marginBottom: "8px", marginTop: 5 }}
                >
                  Views
                </Typography>
                <Typography variant="h4" color="#032E5E">
                  {metrics.views}
                </Typography>
              </Card>
            </Box>
            <Box display="flex" flexDirection="row">
              <Card
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: 5,
                  borderColor: "rgba(0, 158, 197, 0.2)",
                }}
                className="tier-card"
              >
                <Typography
                  variant="h5"
                  color="#032E5E"
                  fontWeight={"bold"}
                  style={{ marginBottom: "8px", marginTop: 5 }}
                >
                  Engagement Rate
                </Typography>
                <Typography variant="h4" color="#35CF60">
                  {metrics.engagementRate}%
                </Typography>
              </Card>
              <Card
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: 5,
                  borderColor: "rgba(0, 158, 197, 0.2)",
                }}
                className="tier-card"
              >
                <Typography
                  variant="h5"
                  color="#032E5E"
                  fontWeight={"bold"}
                  style={{ marginBottom: "8px", marginTop: 5 }}
                >
                  Shares
                </Typography>
                <Typography variant="h4" color="#032E5E">
                  {metrics.shares}
                </Typography>
              </Card>
              <Card
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: 5,
                  borderColor: "rgba(0, 158, 197, 0.2)",
                }}
                className="tier-card"
              >
                <Typography
                  variant="h5"
                  color="#032E5E"
                  fontWeight={"bold"}
                  style={{ marginBottom: "8px", marginTop: 5 }}
                >
                  Bookmarks
                </Typography>
                <Typography variant="h4" color="#032E5E">
                  {metrics.bookmarks}
                </Typography>
              </Card>
              <Card
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: 5,
                  borderColor: "rgba(0, 158, 197, 0.2)",
                }}
                className="tier-card"
              >
                <Typography
                  variant="h5"
                  color="#032E5E"
                  fontWeight={"bold"}
                  style={{ marginBottom: "8px", marginTop: 5 }}
                >
                  Reposts
                </Typography>
                <Typography variant="h4" color="#032E5E">
                  {metrics.reposts}
                </Typography>
              </Card>
              <Card
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: 5,
                  borderColor: "rgba(0, 158, 197, 0.2)",
                }}
                className="tier-card"
              >
                <Typography
                  variant="h5"
                  color="#032E5E"
                  fontWeight={"bold"}
                  style={{ marginBottom: "8px", marginTop: 5 }}
                >
                  Posts
                </Typography>
                <Typography variant="h4" color="#032E5E">
                  {metrics.posts}
                </Typography>
              </Card>
            </Box>
            <Typography
              variant="h5"
              color="#000"
              fontWeight={"bold"}
              style={{ marginBottom: "8px", marginTop: 5 }}
            >
              Platform Performance Metrics
            </Typography>
            <Box display="flex" flexDirection="row">
              <Card
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: 5,
                  borderColor: "rgba(0, 158, 197, 0.2)",
                }}
                className="tier-card"
              >
                <Box
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginBottom: "10px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={["fab", "facebook"]}
                    style={{
                      alignSelf: "center",
                      fontSize: "25px",
                      color: "#395185",
                      marginRight: 20,
                      padding: 5,
                    }}
                  />

                  <Typography variant="h5" color="#032E5E" fontWeight={"bold"}>
                    Facebook
                  </Typography>
                </Box>
                <Box
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <Box
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      textAlign: "left",
                    }}
                  >
                    <Typography variant="p" color="#032E5E">
                      Link Clicks
                    </Typography>
                    <Typography variant="p" color="#032E5E">
                      {metrics.facebook.linkClicks}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      textAlign: "left",
                    }}
                  >
                    <Typography variant="p" color="#032E5E">
                      Signups
                    </Typography>
                    <Typography variant="p" color="#032E5E">
                      {metrics.facebook.signups}
                    </Typography>
                  </Box>
                </Box>
              </Card>
              <Card
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: 5,
                  borderColor: "rgba(0, 158, 197, 0.2)",
                }}
                className="tier-card"
              >
                <Box
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignSelf: "center",
                      background:
                        "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
                      marginRight: "20px",
                      borderRadius: 10,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={["fab", "instagram"]}
                      style={{
                        alignSelf: "center",
                        fontSize: "25px",
                        color: "#fff",
                      }}
                    />
                  </div>

                  <Typography variant="h5" color="#032E5E" fontWeight={"bold"}>
                    Instagram
                  </Typography>
                </Box>
                <Box
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <Box
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      textAlign: "left",
                    }}
                  >
                    <Typography variant="p" color="#032E5E">
                      Link Clicks
                    </Typography>
                    <Typography variant="p" color="#032E5E">
                      {metrics.instagram.linkClicks}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      textAlign: "left",
                    }}
                  >
                    <Typography variant="p" color="#032E5E">
                      Signups
                    </Typography>
                    <Typography variant="p" color="#032E5E">
                      {metrics.instagram.signups}
                    </Typography>
                  </Box>
                </Box>
              </Card>
              <Card
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: 5,
                  borderColor: "rgba(0, 158, 197, 0.2)",
                }}
                className="tier-card"
              >
                <Box
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignSelf: "center",
                      backgroundColor: "#000",
                      marginRight: "20px",
                      borderRadius: 10,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={["fab", "tiktok"]}
                      style={{
                        alignSelf: "center",
                        fontSize: "25px",
                        color: "#fff",
                      }}
                    />
                  </div>

                  <Typography variant="h5" color="#032E5E" fontWeight={"bold"}>
                    TikTok
                  </Typography>
                </Box>
                <Box
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <Box
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      textAlign: "left",
                    }}
                  >
                    <Typography variant="p" color="#032E5E">
                      Link Clicks
                    </Typography>
                    <Typography variant="p" color="#032E5E">
                      {metrics.tiktok.linkClicks}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      textAlign: "left",
                    }}
                  >
                    <Typography variant="p" color="#032E5E">
                      Signups
                    </Typography>
                    <Typography variant="p" color="#032E5E">
                      {metrics.tiktok.signups}
                    </Typography>
                  </Box>
                </Box>
              </Card>
              <Card
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: 5,
                  borderColor: "rgba(0, 158, 197, 0.2)",
                }}
                className="tier-card"
              >
                <Box
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginBottom: "10px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={["fab", "twitter"]}
                    style={{
                      alignSelf: "center",
                      fontSize: "25px",
                      color: "#009EC5",
                      marginRight: 20,
                    }}
                  />

                  <Typography variant="h5" color="#032E5E" fontWeight={"bold"}>
                    Twitter
                  </Typography>
                </Box>
                <Box
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <Box
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      textAlign: "left",
                    }}
                  >
                    <Typography variant="p" color="#032E5E">
                      Link Clicks
                    </Typography>
                    <Typography variant="p" color="#032E5E">
                      {metrics.twitter.linkClicks}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      textAlign: "left",
                    }}
                  >
                    <Typography variant="p" color="#032E5E">
                      Signups
                    </Typography>
                    <Typography variant="p" color="#032E5E">
                      {metrics.twitter.signups}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Box>
          </Card>
        </div>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "black",
            marginTop: 20,
          }}
        >
          <Grid container spacing={4}>
            {tempCampaigns.map((item, index) => (
              <Grid item xs={12} sm={8} md={4} key={index}>
                <Card
                  style={{
                    width: "100%",
                    backgroundColor: "rgba(0, 158,197, .1)",
                    textAlign: "left",
                  }}
                  className="tier-card"
                  onClick={() => navigate(`/campaign-details/${item._id}`)}
                >
                  <Image src={CampaignImage} alt="Campaign" />
                  <Typography
                    variant="h5"
                    color="#032E5E"
                    fontWeight={"bold"}
                    style={{ marginBottom: "8px", marginTop: 5 }} // Add margin bottom for spacing
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="#032E5E"
                    style={{ marginBottom: "4px" }} // Add margin bottom for spacing
                  >
                    Platforms
                  </Typography>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    style={{
                      marginBottom: "8px",
                    }}
                  >
                    {item?.platforms.map((platform, index) => (
                      <>{displaySocialIcons(platform)}</>
                    ))}
                  </Box>
                  <Typography
                    variant="h5"
                    color="#032E5E"
                    style={{ marginBottom: "4px" }} // Add margin bottom for spacing
                  >
                    Therapy Area
                  </Typography>
                  <Typography variant="h5" color="#032E5E">
                    {item.therapyAreas.join(", ")}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div> */}
        {/* {campaigns &&
          campaigns.map((campaign) => (
            <InnerContainer
              key={campaign?.id}
              onClick={() =>
                navigate("campaign-details/" + campaign?.id, {
                  state: { campaign },
                })
              }
            >
              <Text> {campaign.title} </Text>
            </InnerContainer>
          ))} */}
      </div>
    </div>
  );
};

export default PerformanceDashboard;
