import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import Dropzone from "react-dropzone";
import styled from "styled-components";
import PDFImage from "../assets/pdf.png";
import DocIcon from "../assets/doc.png";

const HiddenInput = styled("input")({
  display: "none",
});

const tabs = ["Upload Content", "Select Content"];

const MediaTabs = ({
  onDrop,
  openFileDialog,
  fileInputRef,
  onFilesAdded,
  loadingUpload,
  loading,
  error,
  data,
  handleFileCheck,
  selectedFiles,
  selectedTab,
  setSelectedTab,
  style,
  engagementsLeft,
  platform,
  fileType,
}) => {
  const [isFilePickerActive, setIsFilePickerActive] = useState(false);

  const handleOpenFileDialog = () => {
    if (!isFilePickerActive) {
      setIsFilePickerActive(true);
      try {
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
      } catch (error) {
        console.error("Error opening file picker:", error);
        setIsFilePickerActive(false);
      }
    }
  };

  const handleFilesAdded = (evt) => {
    setIsFilePickerActive(false);
    onFilesAdded(evt);
  };

  return (
    <>
    <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: "space-between" }}>
      <Box sx={style ? style : { width: "45%", marginTop: 2, marginBottom: 2 }}>
        <Tabs
          value={selectedTab}
          onChange={(e, newValue) => setSelectedTab(newValue)}
          variant="fullWidth"
          textColor="inherit"
          indicatorColor="primary"
          sx={{
            backgroundColor: "rgba(3, 46, 94, 0.06)",
            borderRadius: "20px",
            textDecoration: "none",
            "& .MuiTabs-indicator": {
              display: "none",
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab}
              sx={{
                color: "#000",
                "&.Mui-selected": {
                  color: "#fff",
                  backgroundColor: "#009EC5",
                  textDecoration: "none",
                },
                borderRadius: "20px",
              }}
            />
          ))}
        </Tabs>
    
      </Box>
      {engagementsLeft && (  <Box style={{
                  borderRadius: 10,
                  padding: 10,
                  backgroundColor: 'rgba(0,158,197, .1)',
                  height: 'fit-content',
                  alignSelf: 'center',
                }}>
                  <Typography variant="p">Brand Interactions Remaining:</Typography>
                  <Typography variant="p" color={"#009EC5"} style={{fontWeight: 'bold'}}> {engagementsLeft}</Typography>
                  </Box>)}
    </Box>

      {selectedTab === 0 && !loadingUpload &&(
        <>
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={{ width: "100%", gridColumn: "span 2" }}
                >
                  <Typography variant="h6" marginBottom="16px">
                    Upload Image
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    padding="20px"
                    border="1px dashed #00B4D8"
                    borderRadius="10px"
                    width="100%"
                    textAlign="center"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      height="200px"
                      color="textSecondary"
                    >
                      <CloudUploadIcon
                        style={{ fontSize: 40, marginBottom: "16px" }}
                      />
                      <Typography variant="body2">
                        Select a file or drag and drop here
                      </Typography>
                      <Typography variant="body2">
                        JPG, PNG or PDF, file size no more than 10MB
                      </Typography>
                    </Box>
                    <input
                      accept="image/*"
                      id="upload-button"
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFilesAdded}
                    />
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleOpenFileDialog}
                    >
                      SELECT FILE
                    </Button>
                  </Box>
                </Box>
              </div>
            )}
          </Dropzone>
        </>
      )}

{selectedTab === 0 && loadingUpload && (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
            <span className="sr-only">Uploading...</span>
            <Spinner animation="border" role="status">
            </Spinner>
          </div>
        )}

      { selectedTab === 1 && (
      <>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>Error loading files</p>
        ) : (
          data.getFiles && (
            <div className="file-grid">
              {data.getFiles.filter((file) => {
                if(fileType){
                return fileType === 'image' && file.fileType === 'image';
              }  if(platform === "Instagram") {
                      return file.fileType === "image" || file.fileType === "video"
                    } else if(platform === "Twitter") {
                      return file.fileType === "image";
                    }  else if(platform === "Tiktok") {
                      return file.fileType === "video";
                    } else if (platform === "Facebook") {
                      return file.fileType === "image" || file.fileType === "video";
                    } 
              else {
                return file;
              }
              }).map((file) => (
                <div
                  key={file._id}
                  className="file-container"
                  onClick={() => handleFileCheck(file)}
                >
                  {file.fileType === 'image' ? (
                    <img src={file.url} alt={file.name} className="file-image" />
                  ) : file.fileType === 'video' ? (
                    <video src={file.url} alt={file.name} className="file-image" />
                  ) : file.fileType === 'document' ? (
                    <img
                    src={DocIcon}
                    alt={file.name}
                    className="file-image"
                    style={{
                      width: '100%',
                      height: '150px',
                      objectFit: 'cover',
                      display: 'block',
                    }}
                  />
                  ) : file.fileType === 'pdf' ? (
                    <img
                      src={PDFImage}
                      alt={file.name}
                      className="file-image"
                      style={{
                        width: '100%',
                        height: '150px',
                        objectFit: 'cover',
                        display: 'block',
                      }}
                    />
                  ) : (
                    <img src={file.url} alt={file.name} className="file-image" />
                  )}
                  <input
                    type="checkbox"
                    className="file-checkbox"
                    checked={
                      Array.isArray(selectedFiles) &&
                      selectedFiles.some(
                        (selectedFile) => selectedFile._id === file._id
                      )
                    }
                    onChange={(e) => e.stopPropagation()} // Prevent triggering handleFileCheck twice
                  />
                  <div className="file-name">{file.name}</div>
                </div>
              ))}
            </div>
          )
        )}
      </>
      )}
    </>
  );
};

export default MediaTabs;
