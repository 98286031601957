import React, {useRef, useEffect} from 'react';
import { Paper, Box, Typography, Button, IconButton } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CommentIcon from '@mui/icons-material/Comment';
import VideoComponent from './Video';
import { useNavigate } from 'react-router-dom';
import { formatTimestamp } from '../utilities/format';
import { gql, useMutation } from '@apollo/client';

const TRACK_VIEW = gql`
    mutation viewCommunityPost($id: ID!) {
        viewCommunityPost(id: $id){
        id
        }
    }
`;

const PostCard = ({
  post,
  user,
  portalPrefix,
  handleDelete,
  handleJoinCommunity,
  handleLike,
  likedByUser,
  isMember,
  htmlFrom,
  LogoIcon,
}) => {
  const navigate = useNavigate();
  const {
    id,
    title,
    content,
    author,
    community,
    likes,
    likedBy,
    media,
    comments,
    createdAt,
    promoted,
    viewed,
  } = post;

  const [trackView] = useMutation(TRACK_VIEW, {
    variables: { id: post.id },
    skip: viewed,
  });


  const postRef = useRef(null);
  let viewTimeout;

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        console.log('observer entry', entry);
        if (entry.isIntersecting) {
          viewTimeout = setTimeout(() => {
            if(!viewed) trackView(post.id);
          }, 3000);
        } else {
          clearTimeout(viewTimeout);
        }
      });
    });

    if (postRef.current) {
      observer.observe(postRef.current);
    }

    return () => {
      if (postRef.current) {
        observer.unobserve(postRef.current);
      }
      clearTimeout(viewTimeout);
    };
  }, [post]);

  return (
    <Paper key={id} sx={{ marginBottom: 2, padding: 2 }} ref={postRef}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{ display: 'flex', flexDirection: 'row' }}
            className="link"
            onClick={() =>{
                if(!viewed) trackView(post.id);
              navigate(`/${portalPrefix}/feed/post/${id}`, {
                state: {
                  post,
                },
              })}
            }
          >
            <Box
              sx={{
                height: 50,
                width: 50,
                borderRadius: 50,
                marginRight: 2,
              }}
            >
              <img
                src={author.avatar || LogoIcon || 'https://via.placeholder.com/50'}
                style={{
                  height: '100%',
                  width: '100%',
                  borderRadius: 50,
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                {author && promoted && (
                  <Typography variant="body2" color="textSecondary">
                    by {author.firstName} • Promoted{' '}
                    {createdAt && ' • ' + formatTimestamp(createdAt)}
                  </Typography>
                )}
                {author && !promoted && (
                  <Typography variant="body2" color="textSecondary">
                    by {author.firstName}{' '}
                    {createdAt && ' • ' + formatTimestamp(createdAt)}
                  </Typography>
                )}
              </Box>
              <Typography variant="h6">{title}</Typography>
              <Typography variant="body2" color="textSecondary">
                in {community.name}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {console.log('user', typeof user._id, 'author', typeof author.id, author.id === user._id ) }
          {(author.id === user._id || user.role.includes('Admin')) && (
            <Button
              variant="contained"
              color="error"
              sx={{
                marginLeft: 2,
                borderRadius: '50px',
                textTransform: 'none',
                alignSelf: 'flex-end',
              }}
              onClick={() => handleDelete(id)}
            >
              Delete
            </Button>
          )}
          {!isMember(community.id) && (
            <Button
              variant="contained"
              color="primary"
              sx={{
                marginLeft: 2,
                borderRadius: '50px',
                textTransform: 'none',
                alignSelf: 'flex-end',
              }}
              onClick={() => handleJoinCommunity(community.id)}
            >
              Join
            </Button>
          )}
        </Box>
      </Box>
      <Typography variant="body1">{htmlFrom(content)}</Typography>
      {media && media?.fileType === 'image' && (
        <Box sx={{ height: 400, width: '100%', overflow: 'hidden' }}>
          <img
            src={media.url}
            style={{
              width: '100%',
              height: '100%',
              marginTop: 2,
              borderRadius: 4,
            }}
          />
        </Box>
      )}
      {media && media?.url && media?.fileType === 'video' && (
        <Box sx={{ height: 400, width: '100%', overflow: 'hidden' }}>
          <VideoComponent
            media={media}
            style={{
              width: '100%',
              height: '100%',
              marginTop: 2,
              borderRadius: 4,
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <IconButton
          onClick={() => handleLike(id)}
          sx={{
            marginLeft: 1,
            color: likedByUser(likedBy) && 'dodgerblue',
          }}
        >
          <ThumbUpIcon />
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {likes}
        </Typography>
        <IconButton
          onClick={() =>
            navigate(`/${portalPrefix}/feed/post/${id}#comments`, {
              state: {
                post,
              },
            })
          }
          sx={{ marginLeft: 1 }}
        >
          <CommentIcon />
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {comments.length}
        </Typography>
      </Box>
    </Paper>
  );
};

export default PostCard;