import { gql, useMutation, useQuery } from "@apollo/client";
import { Box, Tab, Tabs } from "@mui/material";
import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useUserContext } from "../../providers/UserContext";

const FETCH_CAMPAIGNS = gql`
  query GetCampaigns {
    getMyCampaigns {
      id: _id
      title
      status
      influencer
      platforms
      postingInstructions
      materials {
        url
      }
      campaignId {
        id: _id
        caption
        name
        startDate
        endDate
        therapyAreas {
          name
          active
        }
        description
      }
      studyId {
        title
        condition
      }
    }
  }
`;

const ACTIVATE_CAMPAIGN = gql`
  mutation ActivateCampaign($campaignId: String!) {
    activateCampaign(campaignId: $campaignId) {
      _id
      name
      description
    }
  }
`;

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

const Container = styled.div`
  background-color: ${(props) => props.background || "grey"};
  height: 60px;
  width: 80%;
  margin: 10px 0;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
`;
const InnerContainer = styled.div`
  margin-top: 80px;
  justify-content: center;
`;
const Input = styled.input`
  margin: 10px auto;
  align-self: center;
  width: 400px;
  height: 30px;
`;
// const Button = styled.button`
//   background-color: blue;
//   border-radius: 10px;
//   height: 50px;
//   width: 100px;
//   color: #fff;
// `;
const Text = styled.p`
  color: #000;
  text-align: left;
`;

const StyledLink = styled(NavLink)`
  margin-right: 15px;
  margin-left: 15px;
  color: #fff;
  text-decoration: none;
  &.${(props) => props.activeClassName} {
    color: blue;
  }
  &:hover {
    color: lightblue;
  }
`;

const Row = styled.div`
  display: flex;
  felx-diretion: row;
  justify-content: center;
`;

const items = [
  {
    name: "Asthma Trial",
    nctNumber: "12341231",
    participants: 10,
    active: true,
  },
  {
    name: "COVID Trial",
    nctNumber: "12341231",
    participants: 20,
    active: false,
  },
  {
    name: "Diabetes Trial",
    nctNumber: "12341231",
    participants: 30,
    active: true,
  },
];

const tempCampaigns = [
  {
    id: 1,
    name: "Campaign 1",
    status: "Requested",
    startDate: "01/01/2021",
    endDate: "01/01/2022",
    description: "This is a description of the campaign",
    influencer: "John Doe",
    influencerEmail: "",
    platforms: "Facebook",
    instructions: "Record a video of yourself discussing the therapy Area",
    therapyAreas: "Eczema",
    earningLimit: 2500,
  },
  {
    id: 2,
    name: "Campaign 2",
    status: "Active",
    startDate: "01/01/2021",
    endDate: "01/01/2022",
    description: "This is a description of the campaign",
    influencer: "Jane Doe",
    influencerEmail: "",
    platforms: "Facebook",
    instructions: "Record a video of yourself discussing the therapy Area",
    therapyAreas: "Asthma",
    earningLimit: 2500,
  },
  {
    id: 3,
    name: "Campaign 3",
    status: "Completed",
    startDate: "01/01/2021",
    endDate: "01/01/2022",
    description: "This is a description of the campaign",
    influencer: "John Doe",
    influencerEmail: "",
    platforms: "Facebook",
    instructions: "Record a video of yourself discussing the therapy Area",
    therapyAreas: "Breast Cancer",
    earningLimit: 2500,
  },
];

function ListItem({ item, setStudies }) {
  const navigate = useNavigate();

  // const [toggleActive] = useMutation(gql`
  //   mutation ToggleActive($id: ID!) {
  //     toggleActive(id: $id) {
  //       id
  //       title
  //       nctNumber
  //       active
  //     }
  //   }
  // `);

  const toggleActive = (item) => {
    // toggleActive({ variables: { id: item.id } });
    let temp = { ...item };
    temp.active = !item.active;
    setStudies([temp]);
  };

  console.log("item", item);

  return (
    <Card
      title={item.title}
      style={{ marginTop: 15, marginBottom: 15, cursor: "pointer" }}
      onClick={() => {
        if (item.status !== "Requested")
          navigate(`/dhi/mycampaigns/${item.id}`, {
            state: { item },
          });
      }}
    >
      {/* <Button
        variant="info"
        style={{ marginLeft: 700 }}
        onClick={() => navigate(`details/${item.id}`)}
      >
        More Info
      </Button> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <div>
          {/* <Text>
            <b>Name:</b> {item.name}
          </Text> */}
          <Text>
            <b>Start Date:</b>
            {item.campaignId.startDate &&
              new Date(Number(item.campaignId.startDate))
                .toISOString()
                .slice(0, 10)}
          </Text>
          <Text>
            <b>End Date:</b>
            {item.campaignId.endDate &&
              new Date(Number(item.campaignId.endDate))
                .toISOString()
                .slice(0, 10)}
          </Text>
          {/* <Text>
                <b>Description:</b> {item.description}
            </Text> */}
          {/* <Text>
                <b>Influencer:</b> {item.influencer}
            </Text>
            <Text>
                <b>Influencer Email:</b> {item.influencerEmail}
            </Text> */}
          <Text>
            <b>Therapy Areas:</b> {item.studyId.condition}
          </Text>
          <Text>
            <b>Platforms:</b> {item.platforms}
          </Text>
          {/* <Text>
            <b>Max Earnings Potential:</b> {formatCurrency(item.earningLimit)}
          </Text> */}
        </div>
      </div>
      {item.status === "Requested" && (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button variant="success" onClick={() => toggleActive(item)}>
            Approve
          </Button>
          <Button variant="danger" onClick={() => toggleActive(item)}>
            Deny
          </Button>
        </div>
      )}
    </Card>
  );
}

function ConfirmationModal(props) {
  const handleClose = () => props.setShow(false);
  const handleShow = () => props.setShow(true);

  const handleDelete = () => {
    props.handleDelete();
    handleClose();
  };

  return (
    <>
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Addition</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to add this study?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleDelete}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function Campaigns() {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [selectedTier, setSelectedTier] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [studies, setStudies] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [tabs, setTabs] = useState(["Requested", "Active", "Completed"]);

  const { data, loading, error, refetch } = useQuery(FETCH_CAMPAIGNS, {
    skip: !user,
  });
  const [activateCampaign] = useMutation(ACTIVATE_CAMPAIGN);

  console.log("data", data, loading, error);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (!loading && !error) {
      console.log("data in use effect", data);
      if (data) setCampaigns(data.getMyCampaigns);
    }
  }, [loading, error, data]);

  useEffect(() => {
    document.title = "DHI Campaigns";
  }, []);

  useEffect(() => {
    if (user) {
      refetch();
    }
  }, [user, refetch]);

  return (
    <div className="App">
      <div className="App-body outerRow" style={{ marginTop: 20 }}>
        <div className="container">
          <div className="flexColumn">
            <Box sx={{ width: "45%", marginTop: 2, marginBottom: 2 }}>
              <Tabs
                value={activeTab}
                onChange={handleChange}
                variant="fullWidth"
                textColor="inherit"
                indicatorColor="primary"
                sx={{
                  backgroundColor: "rgba(3, 46, 94, 0.06)",
                  borderRadius: "20px",
                  textDecoration: "none",
                  "& .MuiTabs-indicator": {
                    display: "none",
                  },
                }}
              >
                {tabs.map((tab, index) => (
                  <Tab
                    key={index}
                    label={tab}
                    sx={{
                      color: "#000",
                      "&.Mui-selected": {
                        color: "#fff",
                        backgroundColor: "#009EC5",
                        textDecoration: "none",
                      },
                      borderRadius: "20px",
                    }}
                  />
                ))}
              </Tabs>
            </Box>
            <div className="flexColumn">
              {activeTab === 0 &&
                campaigns &&
                campaigns.map((item, index) => {
                  if (item.status === "Requested")
                    return (
                      <ListItem
                        key={index}
                        item={item}
                        setStudies={setStudies}
                      />
                    );
                })}

              {activeTab === 1 &&
                campaigns &&
                campaigns.map((item, index) => {
                  if (item.status === "Active")
                    return (
                      <ListItem
                        key={index}
                        item={item}
                        setStudies={setStudies}
                      />
                    );
                })}

              {activeTab === 2 &&
                campaigns &&
                campaigns.map((item, index) => {
                  if (item.status === "Completed")
                    return (
                      <ListItem
                        key={index}
                        item={item}
                        setStudies={setStudies}
                      />
                    );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Campaigns;
